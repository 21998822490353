import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  constructor() { }

  public changeDateTimeFormat(format: string) {
    localStorage.setItem("dateTimeFormat", format);
  }

  getDateTimeFormat(): string {
    return localStorage.hasOwnProperty("dateTimeFormat")
      ? localStorage.getItem("dateTimeFormat")
      : null;
  }

  public changeDateFormat(format: string) {
    localStorage.setItem("dateFormat", format);
  }

  getDateFormat(): string {
    return localStorage.hasOwnProperty("dateFormat")
      ? localStorage.getItem("dateFormat")
      : null;
  }

  public changeTimezone(timezone: string) {
    localStorage.setItem("timezone", timezone);
  }

  getTimezone(): string {
    return localStorage.hasOwnProperty("timezone")
      ? localStorage.getItem("timezone")
      : null;
  }

  public changeLoginId(loginId: string) {
    localStorage.setItem("loginId", loginId);
  }

  getLoginId(): string {
    return localStorage.hasOwnProperty("loginId")
      ? localStorage.getItem("loginId")
      : null;
  }

  public changeUserId(userId: string) {
    localStorage.setItem("userId", userId);
  }

  getUserId(): string {
    return localStorage.hasOwnProperty("userId")
      ? localStorage.getItem("userId")
      : null;
  }

  public changeUserIdentifier(userIdentifier: string) {
    localStorage.setItem("userIdentifier", userIdentifier);
  }

  getUserIdentifier(): string {
    return localStorage.hasOwnProperty("userIdentifier")
      ? localStorage.getItem("userIdentifier")
      : null;
  }

  public changeUserName(userName: string) {
    localStorage.setItem("userName", userName);
  }

  getUserName(): string {
    return localStorage.hasOwnProperty("userName")
      ? localStorage.getItem("userName")
      : null;
  }

  public changeInstCode(instCode: string) {
    localStorage.setItem("instCode", instCode);
  }

  getInstCode(): string {
    return localStorage.hasOwnProperty("instCode")
      ? localStorage.getItem("instCode")
      : null;
  }

  public changeInstName(instName: string) {
    localStorage.setItem("instName", instName);
  }

  getInstName(): string {
    return localStorage.hasOwnProperty("instName")
      ? localStorage.getItem("instName")
      : null;
  }

  public changePatientResourceId(patientResourceId: string) {
    localStorage.setItem("patientResourceId", patientResourceId);
  }

  public removePatientResourceId() {
    localStorage.removeItem("patientResourceId");
  }

  getPatientResourceId(): string {
    return localStorage.hasOwnProperty("patientResourceId")
      ? localStorage.getItem("patientResourceId")
      : null;
  }

  public changePatientEmail(patientId: string) {
    localStorage.setItem("patientEmail", patientId);
  }

  getPatientEmail(): string {
    return localStorage.hasOwnProperty("patientEmail")
      ? localStorage.getItem("patientEmail")
      : null;
  }

  getEncounterIdentifier(): string {
    return localStorage.getItem("encounterIdentifier");
  }

  public changePatientId(patientId: string) {
    localStorage.setItem("patientId", patientId);
  }

  getPatientId(): string {
    return localStorage.hasOwnProperty("patientId")
      ? localStorage.getItem("patientId")
      : null;
  }

  public changeFacilityCode(facilityCode: string) {
    localStorage.setItem("facilityCode", facilityCode);
  }

  getFacilityCode(): string {
    return localStorage.hasOwnProperty("facilityCode")
      ? localStorage.getItem("facilityCode")
      : null;
  }

  public changeFacilityName(facilityName: string) {
    localStorage.setItem("facilityName", facilityName);
  }

  getFacilityName(): string {
    return localStorage.hasOwnProperty("facilityName")
      ? localStorage.getItem("facilityName")
      : null;
  }

  public changeMinSession(min: string) {
    localStorage.setItem("minSession", min);
  }

  getMinSession(): number {
    return parseInt(localStorage.getItem("minSession"));
  }

  public changeSecSession(sec: string) {
    localStorage.setItem("secSession", sec);
  }

  getSecSession(): number {
    return parseInt(localStorage.getItem("secSession"));
  }

  public changePractitionerName(practitionerName: string) {
    localStorage.setItem("practitionerName", practitionerName);
  }

  getPractitionerName(): string {
    return localStorage.getItem("practitionerName");
  }

  public changePractitionerRoleId(practitionerRoleId: string) {
    localStorage.setItem("practitionerRoleId", practitionerRoleId);
  }

  getPractitionerRoleId(): string {
    return localStorage.getItem("practitionerRoleId");
  }

  public changeEncounterId(encounterId: string) {
    localStorage.setItem("encounterId", encounterId);
  }

  getEncounterId(): string {
    return localStorage.getItem("encounterId");
  }

  //switchUser
  public changeDefaultPatientResourceId(patientResourceId: string) {
    localStorage.setItem("defaultPatientResourceId", patientResourceId);
  }
  getDefaultPatientResourceId(): string {
    return localStorage.getItem("defaultPatientResourceId");
  }
  public changeDefaultPatientId(value: string) {
    localStorage.setItem("defaultPatientId", value);
  }
  getDefaultPatientId(): string {
    return localStorage.hasOwnProperty("defaultPatientId")
      ? localStorage.getItem("defaultPatientId")
      : null;
  }
  public changeIdType(value: string) {
    localStorage.setItem("idType", value);
  }
  getIdType(): string {
    return localStorage.hasOwnProperty("idType")
      ? localStorage.getItem("idType")
      : null;
  }
  public changeFullName(value: string) {
    localStorage.setItem("fullName", value);
  }
  getFullName(): string {
    return localStorage.hasOwnProperty("fullName")
      ? localStorage.getItem("fullName")
      : null;
  }
  public changePhone(value: string) {
    localStorage.setItem("phone", value);
  }
  getPhone(): string {
    return localStorage.hasOwnProperty("phone")
      ? localStorage.getItem("phone")
      : null;
  }
  public changeEmail(value: string) {
    localStorage.setItem("email", value);
  }
  getEmail(): string {
    return localStorage.hasOwnProperty("email")
      ? localStorage.getItem("email")
      : null;
  }
  public changeFCMToken(value: string) {
    localStorage.setItem("fcmToken", value);
  }
  getFCMToken(): string {
    return localStorage.hasOwnProperty("fcmToken")
      ? localStorage.getItem("fcmToken")
      : null;
  }
  public changeKeyId(value: string) {
    localStorage.setItem("keyId", value);
  }
  getKeyId(): string {
    return localStorage.hasOwnProperty("keyId")
      ? localStorage.getItem("keyId")
      : null;
  }

  public DocumentManifestId(documentManifestId: string) {
    localStorage.setItem("Documen Manifest Id", documentManifestId);
  }
  getDocumentManifestId(): string {
    return localStorage.getItem("Documen Manifest Id");
  }

  public lastestMaternalRecord(lastestMaternalRecord: string) {
    localStorage.setItem("Lastest Maternal Record", lastestMaternalRecord);
  }
  getLastestMaternalRecord(): string {
    return localStorage.getItem("Lastest Maternal Record");
  }

  public changePatientMrn(patientMrn: string) {
    localStorage.setItem("patientMrn", patientMrn);
  }

  getPatientMrn(): string {
    return localStorage.getItem("patientMrn");
  }

  public changePatientBirthDate(patientBirthdate: string) {
    localStorage.setItem("patientBirthdate", patientBirthdate);
  }

  getPatientBirthdate(): string {
    return localStorage.getItem("patientBirthdate");
  }

  public changeBabyKickIdentifier(babyKickIdentifier: string) {
    localStorage.setItem("babyKickIdentifier", babyKickIdentifier);
  }

  getBabyKickIdentifier(): string {
    return localStorage.getItem("babyKickIdentifier");
  }

  public changeBabyKickStartTime(babyKickStartTime: string) {
    localStorage.setItem("babyKickStartTime", babyKickStartTime);
  }

  getBabyKickStartTime(): string {
    return localStorage.getItem("babyKickStartTime");
  }

  public changeBabyKickLastStartTime(babyKickStartTime: string) {
    localStorage.setItem("babyKickLastStartTime", babyKickStartTime);
  }

  getBabyKickLastStartTime(): string {
    return localStorage.getItem("babyKickLastStartTime");
  }

  public changeBabyKickRecordedTime(babyKickRecordedTime: string) {
    localStorage.setItem("babyKickRecordedTime", babyKickRecordedTime);
  }

  getBabyKickRecordedTime(): string {
    return localStorage.getItem("babyKickRecordedTime");
  }

  public changeBabyKickEndTime(babyKickEndTime: string) {
    localStorage.setItem("babyKickEndTime", babyKickEndTime);
  }

  getBabyKickEndTime(): string {
    return localStorage.getItem("babyKickEndTime");
  }

  public changeBabyKickTimes(babyKickTimes: string) {
    localStorage.setItem("babyKickTimes", babyKickTimes);
  }

  getBabyKickTimes(): string {
    return localStorage.getItem("babyKickTimes");
  }

  public changeBabyKickVersion(babyKickVersion: string) {
    localStorage.setItem("babyKickVersion", babyKickVersion);
  }

  getBabyKickVersion(): string {
    return localStorage.getItem("babyKickVersion");
  }

  public patientGender(patientGender: string) {
    localStorage.setItem("patientGender", patientGender);
  }

  getPatientGender(): string {
    return localStorage.getItem("patientGender");
  }

  public patientAge(patientAge: string) {
    localStorage.setItem("patientAge", patientAge);
  }

  getPatientAge(): string {
    return localStorage.getItem("patientAge");
  }

  public changeDreamService(dreamService: string) {
    localStorage.setItem("dreamService", dreamService);
  }

  getDreamService(): string {
    return localStorage.getItem("dreamService");
  }

  public changeWholeBloodEligibleDate(wholeBloodEligibleDate: string) {
    localStorage.setItem("wholeBloodEligibleDate", wholeBloodEligibleDate);
  }
  getWholeBloodEligibleDate(): string {
    return localStorage.getItem("wholeBloodEligibleDate");
  }
  public removeWholeBloodEligibleDate() {
    localStorage.removeItem("wholeBloodEligibleDate");
  }

  public changeApheresisEligibleDate(apheresisEligibleDate: string) {
    localStorage.setItem("apheresisEligibleDate", apheresisEligibleDate);
  }
  getApheresisEligibleDate(): string {
    return localStorage.getItem("apheresisEligibleDate");
  }
  public removeApheresisEligibleDate() {
    localStorage.removeItem("apheresisEligibleDate");
  }

  public changePermanentDefer(permanentDefer: string) {
    localStorage.setItem("permanentDefer", permanentDefer);
  }
  getPermanentDefer(): string {
    return localStorage.getItem("permanentDefer");
  }
  public removePermanentDefer() {
    localStorage.removeItem("permanentDefer");
  }

  public changeSelectedAppointment(selectedAppointment: string) {
    localStorage.setItem("selectedAppointment", selectedAppointment);
  }

  getSelectedAppointment(): string {
    return localStorage.getItem("selectedAppointment");
  }
  public removeSelectedAppointment() {
    localStorage.removeItem("selectedAppointment");
  }
  public changeReferenceNumber(referenceNumber: string) {
    localStorage.setItem("referenceNumber", referenceNumber);
  }

  getReferenceNumber(): string {
    return localStorage.getItem("referenceNumber");
  }
  public removeReferenceNumber() {
    localStorage.removeItem("referenceNumber");
  }

  public changeBankCode(bankCode: string) {
    localStorage.setItem("bankCode", bankCode);
  }

  getBankCode(): string {
    return localStorage.getItem("bankCode");
  }
  public removeBankCode() {
    localStorage.removeItem("bankCode");
  }


  public changeUser(changeUser: string) {
    localStorage.setItem("changeUser", changeUser);
  }

  getChangeUser(): string {
    return localStorage.getItem("changeUser");
  }
  public removeChangeUser() {
    localStorage.removeItem("changeUser");
  }
}
