import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
  throwError,
  of,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import { ReferenceCode } from "app/shared/ReferenceCode";
import { NcrConfigService } from "app/ncrconfig.service";
import moment from "moment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

const fhirHttpOptions = {
  headers: new HttpHeaders({
    "cache-control": "no-cache",
    Accept: "application/fhir+json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  /**
   * Constructor
   */

  public _checkinEnable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  get checkinEnable$(): Observable<any> {
    return this._checkinEnable.asObservable();
  }

  private _appointmentBooked: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private _appointmentFulfilled: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private _appointmentCancelled: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  get appointmentBooked$(): Observable<any[]> {
    return this._appointmentBooked.asObservable();
  }
  get appointmentFulfilled$(): Observable<any[]> {
    return this._appointmentFulfilled.asObservable();
  }
  get appointmentCancelled$(): Observable<any[]> {
    return this._appointmentCancelled.asObservable();
  }

  private _schedulesBooked: ReplaySubject<fhir.r4.Schedule[]> =
    new ReplaySubject<fhir.r4.Schedule[]>(1);
  // private _appointments: ReplaySubject<fhir.r4.Appointment[]> =
  //   new ReplaySubject<fhir.r4.Appointment[]>(1);

  private _healthcareservices: ReplaySubject<fhir.r4.HealthcareService[]> =
    new ReplaySubject<fhir.r4.HealthcareService[]>(1);
  private _healthcareservicesType: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);

  private _practitionerroles: ReplaySubject<fhir.r4.PractitionerRole[]> =
    new ReplaySubject<fhir.r4.PractitionerRole[]>(1);
  private _practitionerrole: ReplaySubject<any[]> = new ReplaySubject<
  any[]
  >(1);
  private _specialtys: ReplaySubject<any[]> = new ReplaySubject<
  any[]
  >(1);

  public _schedulesSearch: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public _slotsSearch: ReplaySubject<fhir.r4.Slot[]> = new ReplaySubject<
    fhir.r4.Slot[]
  >(1);

  private _states: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _districts: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _genders: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _organizationcategorys: ReplaySubject<any> = new ReplaySubject<any>(
    1
  );
  private _organizations: ReplaySubject<fhir.r4.Organization[]> =
    new ReplaySubject<fhir.r4.Organization[]>(1);
  private _patient: ReplaySubject<fhir.r4.Patient> =
    new ReplaySubject<fhir.r4.Patient>(1);
  public eventSubject = new BehaviorSubject<any>(undefined);
  private switchForm = new BehaviorSubject<any>(undefined);
  public _appointmentsLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private _bankgroup: BehaviorSubject<ReferenceCode> = new BehaviorSubject(
    null
  );

  get schedulesBooked$(): Observable<fhir.r4.Schedule[]> {
    return this._schedulesBooked.asObservable();
  }

  get healthcareServices$(): Observable<fhir.r4.HealthcareService[]> {
    return this._healthcareservices.asObservable();
  }
  get healthcareServicesType$(): Observable<any[]> {
    return this._healthcareservicesType.asObservable();
  }

  get practitionerRole$(): Observable<any[]> {
    return this._practitionerrole.asObservable();
  }

  get specialtys$(): Observable<any[]> {
    return this._specialtys.asObservable();
  }

  get schedulesSearch$(): Observable<any[]> {
    return this._schedulesSearch.asObservable();
  }
  get slotsSearch$(): Observable<fhir.r4.Slot[]> {
    return this._slotsSearch.asObservable();
  }

  get states$(): Observable<any[]> {
    return this._states.asObservable();
  }
  get districts$(): Observable<any[]> {
    return this._districts.asObservable();
  }
  get genders$(): Observable<any[]> {
    return this._genders.asObservable();
  }
  get organizationCategorys$(): Observable<any[]> {
    return this._organizationcategorys.asObservable();
  }
  get organizations$(): Observable<fhir.r4.Organization[]> {
    return this._organizations.asObservable();
  }
  get patient$(): Observable<fhir.r4.Patient> {
    return this._patient.asObservable();
  }
  get _appointmentsLoading$(): Observable<boolean> {
    return this._appointmentsLoading.asObservable();
  }
  get bankGroup$(): Observable<any> {
    return this._bankgroup.asObservable();
  }
  fhirHttpOptions = {
    headers: new HttpHeaders({
      "Cache-Control": "no-cache",
      Accept: "application/fhir+json",
    }),
  };
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _appConfigService: AppConfigService,
    private _ncrConfigService: NcrConfigService
  ) { }
  clear(): Observable<any> {
    this._schedulesSearch.next([]);
    this._slotsSearch.next([]);
    return null;
  }

  getAppointmentBooked(): Observable<fhir.r4.Bundle> {
    let _buildFilter: string = null;
    if (this._appConfigService.getChangeUser() == "false") {
      _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();
    } else {
      if (this._appConfigService.getPatientResourceId() == null) { _buildFilter = "type=keyid&id=" + this._appConfigService.getKeyId(); } else {
        _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();

      }

    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._ncrConfigService.getNcrService() +
        "/api/v1/appointment/hie?status=booked,arrived&" + _buildFilter
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let schedules: fhir.r4.Schedule[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Schedule)"
          );
          let appointments: fhir.r4.Schedule[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Appointment)"
          );
          this._appointmentBooked.next(this.mappingData(response));
          this._schedulesBooked.next(schedules);

          // this.checkStatus(appointments);
        })
      );
  }

  checkStatus(appointments) {
    //  var time = new Date().getTime() - new Date(data.start).getTime();

    for (let i = 0; i < appointments.length; i++) {
      let data = appointments[i];
      if (
        data.serviceCategory[0].coding[0].display !== "Virtual" &&
        (data.status != "cancelled" ||
          data.status != "fulfilled" ||
          data.status != "noshow")
      ) {
        this._checkinEnable.next(false);
        break;
      } else {
        // create a new Date object with the current date and time
        const now = new Date();
        // create a new Date object for the target date
        const targetDate = new Date(data.start);

        // calculate the difference in milliseconds between the two dates
        const diffMs: number = +targetDate - +now;

        // check if the difference is more than one hour in milliseconds (3600000) (supposed not show yet unless status is arrived)
        //if true will hide
        if (diffMs > 3600000) {
          this._checkinEnable.next(false);
        } else {
          //if alr fill up pre requisete no need to fill up again
          if (data.status === "arrived") {
            this._checkinEnable.next(false);
          } else {
            // now.setHours(now.getHours() + 1);
            if (targetDate <= now) {
              this._checkinEnable.next(false);
            }
            this._checkinEnable.next(true);
          }
        }
      }
      console.log("VV", data.serviceCategory[0].coding[0].display);
    }
  }

  getAppointmentFullfilled(): Observable<fhir.r4.Bundle> {
    let _buildFilter: string = null;
    if (this._appConfigService.getChangeUser() == "false") {
      _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();
    } else {
      if (this._appConfigService.getPatientResourceId() == null) { _buildFilter = "type=keyid&id=" + this._appConfigService.getKeyId(); } else {
        _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();

      }

    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._ncrConfigService.getNcrService() +
        "/api/v1/appointment/hie?status=fulfilled&" + _buildFilter
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          this._appointmentFulfilled.next(this.mappingData(response));
        })
      );
  }

  getAppointmentCancelled(): Observable<fhir.r4.Bundle> {
    let _buildFilter: string = null;
    if (this._appConfigService.getChangeUser() == "false") {
      _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();
    } else {
      if (this._appConfigService.getPatientResourceId() == null) { _buildFilter = "type=keyid&id=" + this._appConfigService.getKeyId(); } else {
        _buildFilter = "type=patient&id=" + this._appConfigService.getPatientResourceId();

      }

    }

    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._ncrConfigService.getNcrService() +
        "/api/v1/appointment/hie?status=cancelled,noshow&" + _buildFilter
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          this._appointmentCancelled.next(this.mappingData(response));
        })
      );
  }

  mappingData(response: fhir.r4.Bundle): any[] {
    let currentDate = new Date();

    let appointments: fhir.r4.Appointment[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Appointment)"
    );
    let schedules: fhir.r4.Schedule[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Schedule)"
    );
    let slots: fhir.r4.Slot[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Slot)"
    );
    let healthcareservices: fhir.r4.HealthcareService[] =
      this._fhirPathService.evaluate(
        response,
        "entry.resource.ofType(HealthcareService)"
      );
    let locations: fhir.r4.Location[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Location)"
    );
    let practitioner: fhir.r4.Practitioner[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Practitioner)"
    );
    let practitionerroles: fhir.r4.PractitionerRole[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(PractitionerRole)"
    );
    let organizations: fhir.r4.Organization[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Organization)"
    );
    let invoices: fhir.r4.Invoice[] = this._fhirPathService.evaluate(
      response,
      "entry.resource.ofType(Invoice)"
    );
    let paymentReconciliations: fhir.r4.PaymentReconciliation[] =
      this._fhirPathService.evaluate(
        response,
        "entry.resource.ofType(PaymentReconciliation)"
      );

    let listappointment: any[] = [];

    appointments.forEach((element) => {

      let slot: fhir.r4.Slot = null;
      let schedule: fhir.r4.Schedule = null;

      if (element.slot != null) {
        slot = slots.find(
          (x) => "Slot/" + x.id == element.slot[0].reference
        );
        schedule = schedules.find(
          (x) => "Schedule/" + x.id == slot.schedule.reference
        );
      }

      let practitionerrole: fhir.r4.PractitionerRole = null;
      let healthcareservice: fhir.r4.HealthcareService = null;
      let location: fhir.r4.Location = null;
      let locationDisplay: string = null;
      let healthcareserviceDisplay: string = null;
      let practitionerroleDisplay: string = null;
      element.participant.forEach((elementparticipant) => {
        if (healthcareservices.length > 0 && practitionerroles.length == 0) {

          if (healthcareservice == null) {
            healthcareservice = healthcareservices.find(
              (x) =>
                "HealthcareService/" + x.id == elementparticipant.actor.reference
            );
          } else {
            if (elementparticipant.actor.type == "HealthcareService")
              healthcareserviceDisplay = elementparticipant.actor.display;
          }
  
          if (location == null)
            location = locations.find(
              (x) => "Location/" + x.id === elementparticipant.actor.reference
            );
          if (elementparticipant.actor.type == "Location")
            locationDisplay = elementparticipant.actor.display;

        } else if (practitionerroles.length > 0 && healthcareservices.length == 0) {

          if (practitionerrole == null) {
            practitionerrole = practitionerroles.find(
              (x) =>
                "PractitionerRole/" + x.id == elementparticipant.actor.reference
            );
          } else {
            if (elementparticipant.actor.type == "PractitionerRole")
              practitionerroleDisplay = elementparticipant.actor.display;
          }
  
          if (location == null)
            location = locations.find(
              (x) => "Location/" + x.id === elementparticipant.actor.reference
            );
          if (elementparticipant.actor.type == "Location")
            locationDisplay = elementparticipant.actor.display;

        } else if (practitionerroles.length > 0 && healthcareservices.length > 0) {

          if (healthcareservice == null) {
            healthcareservice = healthcareservices.find(
              (x) =>
                "HealthcareService/" + x.id == elementparticipant.actor.reference
            );
          } else {
            if (elementparticipant.actor.type == "HealthcareService")
              healthcareserviceDisplay = elementparticipant.actor.display;
          }

          if (practitionerrole == null) {
            practitionerrole = practitionerroles.find(
              (x) =>
                "PractitionerRole/" + x.id == elementparticipant.actor.reference
            );
          } else {
            if (elementparticipant.actor.type == "PractitionerRole")
              practitionerroleDisplay = elementparticipant.actor.display;
          }
  
          if (location == null)
            location = locations.find(
              (x) => "Location/" + x.id === elementparticipant.actor.reference
            );
          if (elementparticipant.actor.type == "Location")
            locationDisplay = elementparticipant.actor.display;
          
        }
        
      });
      let organization: fhir.r4.Organization = null;
      if (healthcareservices.length > 0 && practitionerroles.length == 0) {

        if (healthcareservice != null) {
          healthcareserviceDisplay = healthcareservice.name;
          organization = organizations.find(
            (x) =>
              "Organization/" + x.id == healthcareservice.providedBy.reference
          );
        }
        if (location != null) {
          locationDisplay = location.name;
        }
      } 
      else if (practitionerroles.length > 0 && healthcareservices.length == 0) {

        if (practitionerrole != null) {
          // healthcareserviceDisplay = organization.name;
          organization = organizations.find(
            (x) =>
            "Organization/" + x.id == practitionerrole.organization.reference
          );
        }
        if (location != null) {
          locationDisplay = location.name;
        }
      }
      else if (practitionerroles.length > 0 && healthcareservices.length > 0) {

        if (healthcareservice != null) {
          healthcareserviceDisplay = healthcareservice.name;
          organization = organizations.find(
            (x) =>
              "Organization/" + x.id == healthcareservice.providedBy.reference
          );
        }
        
        if (practitionerrole != null) {
          // healthcareserviceDisplay = organization.name;
          organization = organizations.find(
            (x) =>
            "Organization/" + x.id == practitionerrole.organization.reference
          );
        }
        if (location != null) {
          locationDisplay = location.name;
        }
      }

      if (healthcareserviceDisplay == null) {
        healthcareserviceDisplay = element.specialty[0].coding[0].display;
      }

      let source = element.meta.source;

      let elem: any = <any>{
        appointment: element,
        source: source,
        slot: slot,
        schedule: schedule,
        healthcareservice: healthcareservice,
        location: location,
        organization: organization,
        locationDisplay: locationDisplay,
        healthcareserviceDisplay: healthcareserviceDisplay,
        servicecategory: element.serviceCategory[0].coding[0].display,
        servicetype: element.serviceType[0].coding[0].display,
        appointmenttype: element.appointmentType.coding[0].display,
        start: element.start,
        end: element.end,
        status: element.status,
      };
      if (invoices.length > 0) {
        elem.invoice = invoices[0];
      }
      if (organization == null && source.includes("http://cprchospital.moh.gov.my")) {
        elem.organizationDisplay = element.supportingInformation[0].display;
      } 
      if (slot == null) {
        elem.scheduleDisplay = element.specialty[0].coding[0].display;
      }

      listappointment.push(elem);

    });
    return listappointment;
  }

  getHealthcareServices(
    organizationCode: string
  ): Observable<fhir.r4.HealthcareService[]> {
    return this._httpClient
      .get<fhir.r4.HealthcareService[]>(
        this._fhirConfigService.getFhirService() +
        "/HealthcareService?" +
        "organization=" +
        organizationCode +
        "&specialty:not=532,533,534,535,536,537,538,539,540,541&_count=1000&service-category:not=108252007,363679005,VRF&active=true"
      )
      .pipe(
        tap((response) => {
          console.log("responseHealthcareS", response);
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
          let healthcareservices: fhir.r4.HealthcareService[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(HealthcareService)"
            );
          let healthcareServiceGroup: any[] = [];
          healthcareservices?.forEach((item) => {
            let newevent = {
              display: this._fhirPathService.evaluateToString(item, "name"),
              code: this._fhirPathService.evaluateToString(item, "id"),
              type: this._fhirPathService.evaluateToString(
                item,
                "type.first().coding.first().display"
              ),
            };
            healthcareServiceGroup.push(newevent);
          });
          this._healthcareservicesType.next(healthcareServiceGroup);
        })
      );
  }

  getSpecialty(
    organizationCode: string
  ): Observable<fhir.r4.PractitionerRole[]> {
    return this._httpClient
      .get<fhir.r4.PractitionerRole[]>(
        this._fhirConfigService.getFhirService() +
        "/PractitionerRole?" +
        "organization=" +
        organizationCode +
        "&scheduling=true"
      )
      .pipe(
        tap((response) => {
          console.log("responseSpecialty", response);
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
          let specialtys: fhir.r4.PractitionerRole[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(PractitionerRole).specialty.coding"
            );

          let specialty: any[] = [];
          specialtys?.forEach((item) => {
              let newevent = {
                display: this._fhirPathService.evaluateToString(item, "display"),
                code: this._fhirPathService.evaluateToString(item, "code"),
              };
              if (
                !specialty.some((x) =>  x.code === newevent.code)) {
              specialty.push(newevent);
              }
          });
          this._specialtys.next(specialty);
          
        })
      );
  }

  getPractitionerRole(
    specialtyCode: string,
    organizationCode: string
  ): Observable<fhir.r4.PractitionerRole[]> {
    return this._httpClient
      .get<fhir.r4.PractitionerRole[]>(
        this._fhirConfigService.getFhirService() +
        "/PractitionerRole?" +
        "organization=" +
        organizationCode +
        "&specialty=" +
        specialtyCode +
        "&scheduling=true"
      )
      .pipe(
        tap((response) => {
          console.log("responsePractitioner", response);
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
          let practititonerroles: fhir.r4.PractitionerRole[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(PractitionerRole)"
            );
          let practitionerRole: any[] = [];
          practititonerroles?.forEach((item) => {
            let newevent = {
              display: this._fhirPathService.evaluateToString(item, "practitioner.display"),
              code: this._fhirPathService.evaluateToString(item, "id"),
              // display: this._fhirPathService.evaluateToString(item, "name"),
              // code: this._fhirPathService.evaluateToString(item, "id"),
              // type: this._fhirPathService.evaluateToString(
              //   item,
              //   "type.first().coding.first().display"
              // ),
            };
            practitionerRole.push(newevent);
          });
          this._practitionerrole.next(practitionerRole);
          
        })
      );
  }

  getSlots(id: string): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
        "/Slot?schedule=" +
        id +
        "&_count=500&_sort=start&status=free"
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);

          let slots: fhir.r4.Slot[] = this._fhirPathService.evaluate(
            jsonObject,
            "entry.resource.ofType(Slot)"
          );
          this._slotsSearch.next(slots);
        })
      );
  }
  getStates(): Observable<any> {
    return this._httpClient
      .get<fhir.r4.ValueSet>(
        this._fhirConfigService.getFhirService() +
        "/ValueSet/value-set-state-my-core/$expand"
      )
      .pipe(
        tap((response) => {
          let states: any[] = response.expansion.contains;
          states = states.filter(
            (x) => x.display != "Others" && x.display != "No Information"
          );
          this._states.next(states);
        })
      );
  }
  getDistricts(stateCode: string): Observable<any> {
    return this._httpClient
      .get<fhir.r4.ValueSet>(
        this._fhirConfigService.getFhirService() +
        "/CodeSystem/code-system-district-my-core"
      )
      .pipe(
        tap((response: fhir.r4.CodeSystem) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.CodeSystem = JSON.parse(letjstring);
          let districts: any[] = jsonObject.concept.filter(
            (x) => x.property[0].valueCode === stateCode
          );
          this._districts.next(districts);
        })
      );
  }
  getGenders(): Observable<any> {
    return this._httpClient
      .get<fhir.r4.ValueSet>(
        this._fhirConfigService.getFhirService() +
        "/ValueSet/value-set-administrative-gender-my-core"
      )
      .pipe(
        tap((response) => {
          this._genders.next(response.compose.include[0].concept);
        })
      );
  }
  getOrganizationCategory(): Observable<any> {
    return this._httpClient
      .get<fhir.r4.ValueSet>(
        this._fhirConfigService.getFhirService() +
        "/ValueSet/value-set-organization-category-public-my-core"
      )
      .pipe(
        tap((response) => {
          this._organizationcategorys.next(response.compose.include[0].concept);
        })
      );
  }
  getHealthOrganizations(
    stateCode: string,
    districtCode: string,
    categoryCode: string
  ): Observable<fhir.r4.Organization[]> {
    let listCode: string;
    if (categoryCode === "CLI") listCode = "K1M,CLB,CLD,CLI,CWC,CLC";
    else if (categoryCode === "KP")
      listCode = "KP,KPH,KPI,KPKK,KPKKIA,KPKKUTC,KPRTC,KPS,KPUTC,KPRTC";
    else if (categoryCode === "STH") listCode = "MJS,MNS,STH,NOS,UNH";
    else if (categoryCode === "PVT") listCode = "OTH&type=2";

    let mDistrict: string;
    if (districtCode == null) mDistrict = "";
    else mDistrict = "&organization-district=" + districtCode;

    return this._httpClient
      .get<fhir.r4.Organization[]>(
        this._fhirConfigService.getFhirService() +
        "/Organization?" +
        "&organization-state=" +
        stateCode +
        mDistrict +
        "&organization-category=" +
        listCode
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);

          let organizations: fhir.r4.Organization[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(Organization)"
            );
          this._organizations.next(organizations);
        })
      );
  }
  getHealthOrganizationPractitioner(
    categoryCode: string
  ): Observable<fhir.r4.Organization[]> {
    let listCode: string;
    if (categoryCode === "CLI") listCode = "K1M,CLB,CLD,CLI,CWC,CLC";
    else if (categoryCode === "KP")
      listCode = "KP,KPH,KPI,KPKK,KPKKIA,KPKKUTC,KPRTC,KPS,KPUTC,KPRTC";
    else if (categoryCode === "STH") listCode = "MJS,MNS,STH,NOS,UNH";
    else if (categoryCode === "PVT") listCode = "OTH&type=2";

    return this._httpClient
      .get<fhir.r4.Organization[]>(
        this._fhirConfigService.getFhirService() +
        "/Organization?" +
        "&organization-category=" +
        listCode
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);

          let organizations: fhir.r4.Organization[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(Organization)"
            );
          this._organizations.next(organizations);
        })
      );
  }
  

  getSchedules(
    organizationCode: string,
    healthcareServiceCode: string,
    calendarFirstDate: string,
    calendarLastDate: string,
    hasSearchForm,
    hasPractitionerForm
  ): Observable<fhir.r4.Bundle> {
    if(hasSearchForm === true) {
      return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
        "/Schedule?" +
        "schedule-type=public&_count=5000&_include=Schedule:actor" +
        "&actor:HealthcareService.organization=" +
        organizationCode +
        "&actor=HealthcareService/" +
        healthcareServiceCode +
        "&date=ge" +
        calendarFirstDate +
        "&date=le" +
        calendarLastDate +
        "&_revinclude=Slot:schedule&_include=Schedule:actor&_include:iterate=HealthcareService:organization"
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let schedules: fhir.r4.Schedule[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Schedule)"
          );

          let healthcareservices: fhir.r4.HealthcareService[] =
            this._fhirPathService.evaluate(
              response,
              "entry.resource.ofType(HealthcareService)"
            );

          let locations: fhir.r4.Location[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Location)"
          );

          let slots: fhir.r4.Slot[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Slot)"
          );

          let listschedule: any[] = [];
          schedules.forEach((element) => {
            let healthcareservice: fhir.r4.HealthcareService = null;
            let location: fhir.r4.Location = null;

            element.actor.forEach((elementActor) => {
              if (healthcareservice == null) {
                healthcareservice = healthcareservices.find(
                  (x) => "HealthcareService/" + x.id == elementActor.reference
                );
              }

              if (location == null) {
                location = locations.find(
                  (x) => "Location/" + x.id == elementActor.reference
                );
              }
            });

            let scheduleslots: fhir.r4.Slot[] = slots.filter(
              (x) => x.schedule.reference == "Schedule/" + element.id
            );

            let totalQuota: number = 0;
            let totalAvailable: number = 0;
            let statusSlot: string = "";

            scheduleslots.forEach((elementslot) => {
              statusSlot = elementslot.status;
              let quota = elementslot.extension
                .find(
                  (x) =>
                    x.url ==
                    "http://fhir.hie.moh.gov.my/StructureDefinition/slot-quota-my-core"
                )
                .extension.find((y) => y.url == "quota").valueInteger;
              let quotanumber: number = Number(quota);
              totalQuota = totalQuota + quotanumber;

              let available = elementslot.extension
                .find(
                  (x) =>
                    x.url ==
                    "http://fhir.hie.moh.gov.my/StructureDefinition/slot-quota-my-core"
                )
                .extension.find((y) => y.url == "available").valueInteger;
              let availablenumber: number = Number(available);
              totalAvailable = totalAvailable + availablenumber;
              console.log(totalAvailable);
            });

            let elem: any = <any>{
              schedule: element,
              healthcareservice: healthcareservice,
              location: location,
              scheduleslots: scheduleslots,
              totalquota: totalQuota,
              totalavailable: totalAvailable,
            };
            if (statusSlot == "free") listschedule.push(elem);
          });

          this._schedulesSearch.next(listschedule);
        })
      );
    } else if (hasPractitionerForm === true) {
      return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
        "/Schedule?" +
        // "&date=ge2024-02-01" +
        "&date=ge" +
        calendarFirstDate +
        // "&date=le2024-03-01" +
        "&date=le" +
        calendarLastDate +
        "&schedule-type=&_count=5000&active=true&_include=Schedule:actor" +
        "&actor=PractitionerRole/" +
        healthcareServiceCode +
        "&actor:PractitionerRole.organization=" +
        organizationCode +
        "&_revinclude=Slot:schedule&_include=Schedule:actor&_include:iterate=PractitionerRole:organization"
        // "/Schedule?actor=PractitionerRole/" +
        // healthcareServiceCode
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let schedules: fhir.r4.Schedule[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Schedule)"
          );

          let practitionerroles: fhir.r4.PractitionerRole[] =
            this._fhirPathService.evaluate(
              response,
              "entry.resource.ofType(PractitionerRole)"
            );

          let locations: fhir.r4.Location[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Location)"
          );

          let slots: fhir.r4.Slot[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Slot)"
          );

          let listschedule: any[] = [];
          schedules.forEach((element) => {
            let practitionerrole: fhir.r4.PractitionerRole = null;
            let location: fhir.r4.Location = null;

            element.actor.forEach((elementActor) => {
              console.log('elementActor.reference:', elementActor.reference);
              if (practitionerrole == null) {
                practitionerrole = practitionerroles.find(
                  (x) => "PractitionerRole/" + x.id == elementActor.reference
                );
                console.log('practitionerrole:', practitionerrole);
              }

              if (location == null) {
                location = locations.find(
                  (x) => "Location/" + x.id == elementActor.reference
                );
              }
            });

            let scheduleslots: fhir.r4.Slot[] = slots.filter(
              (x) => x.schedule.reference == "Schedule/" + element.id
            );

            let totalQuota: number = 0;
            let totalAvailable: number = 0;
            let statusSlot: string = "";

            scheduleslots.forEach((elementslot) => {
              statusSlot = elementslot.status;
              let quota = elementslot.extension
                .find(
                  (x) =>
                    x.url ==
                    "http://fhir.hie.moh.gov.my/StructureDefinition/slot-quota-my-core"
                )
                .extension.find((y) => y.url == "quota").valueInteger;
              let quotanumber: number = Number(quota);
              totalQuota = totalQuota + quotanumber;

              let available = elementslot.extension
                .find(
                  (x) =>
                    x.url ==
                    "http://fhir.hie.moh.gov.my/StructureDefinition/slot-quota-my-core"
                )
                .extension.find((y) => y.url == "available").valueInteger;
              let availablenumber: number = Number(available);
              totalAvailable = totalAvailable + availablenumber;
              console.log(totalAvailable);
            });

            let elem: any = <any>{
              schedule: element,
              practitionerrole: practitionerrole,
              location: location,
              scheduleslots: scheduleslots,
              totalquota: totalQuota,
              totalavailable: totalAvailable,
            };
            if (statusSlot == "free") listschedule.push(elem);
            console.log(elem)
          });

          this._schedulesSearch.next(listschedule);
        })
      );
    }
    
  }

  getPatient(patientId: string): Observable<fhir.r4.Patient> {
    return this._httpClient
      .get<fhir.r4.Patient>(
        this._fhirConfigService.getFhirService() + "/Patient/" + patientId
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Patient = JSON.parse(letjstring);

          this._patient.next(jsonObject);
        })
      );
  }

  postAppointment(bundle: fhir.r4.Bundle): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .post<fhir.r4.Bundle>(this._fhirConfigService.getFhirService(), bundle)
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
        })
      );
  }

  /*** Get appointment by id*/
  getAppointmentById(id: string): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._fhirConfigService.getFhirService() +
        "/Appointment?_id=" +
        id +
        "&_include=Appointment:supporting-info&_include=Appointment:patient&actor=Patient/" + this._appConfigService.getPatientResourceId()
      )
      .pipe(
        take(1),
        map((appoinment) => {
          // Update the patient
          // this._patient.next(encounter);
          this._appointmentsLoading.next(false);
          // Return the patient
          return appoinment;
        }),
        switchMap((appoinment) => {
          if (!appoinment) {
            return throwError(
              () => new Error("Could not found schedule with id of " + id + "!")
            );
          }
          return of(appoinment);
        })
      );
  }

  getBankGroup(): Observable<any> {
    const query = `
		  {
			bankGroup:AFCRefcd(filter:"moduleId=='AF';referenceGroup=='BANK_GROUP'") {
			  edges {
				node {
				  referenceCode
				  fullDesc
				}
			  }
			}
		  }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this._bankgroup.next(
            response.data.bankGroup.edges.map((edge) => edge.node)
          );
          return response;
        })
      );
  }

  switchView() {
    this.switchForm.next("emit");
  }

  getSwitchView(): BehaviorSubject<any> {
    return this.switchForm;
  }

  triggerSomeEvent(item) {
    this.eventSubject.next(item);
  }

  getEventSubject(): BehaviorSubject<any> {
    return this.eventSubject;
  }

  createObservation(compositionBundle: fhir.r4.Bundle) {
    return this._httpClient.post(
      this._fhirConfigService.getFhirService(),
      compositionBundle
    );
  }

  getObservationById(id: string) {
    return this._httpClient
      .get(this._fhirConfigService.getFhirService() + "Observation/" + id)
      .pipe(take(1));
  }

  getPatientHieRecord() {
    let patientId = this._appConfigService.getPatientResourceId();
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Patient?_id=" +
        patientId +
        "&_source=http://provider.hie.moh.gov.my"
      )
      .pipe(take(1));
  }

  validatePatientById(id: string): Observable<any> {
    return this._httpClient.get(
      this._fhirConfigService.getFhirService() + "/Patient/" + id, fhirHttpOptions
    );
  }

  getAppointment(id: string): Observable<any> {
    return this._httpClient.get(this._fhirConfigService.getFhirService() + "/Appointment/" + id, fhirHttpOptions);
  }

  updateAppointmentStatus(id: string, query: object): Observable<any> {
    return this._httpClient.put(
      this._fhirConfigService.getFhirService() + "/Appointment/" + id,
      query
    );
  }

  createBundle(bundle: any) {
    return this._httpClient.post(this._fhirConfigService.getFhirService(), bundle);
  }
}
