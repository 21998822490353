import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { CadMediaWatcherService } from "@cad/services/media-watcher";
import {
  CadNavigationService,
  CadVerticalNavigationComponent,
} from "@cad/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import {
  IgxDialogComponent,
  rotateInCenter,
} from "@infragistics/igniteui-angular";
import { AuthService } from "app/core/auth/auth.service";
import { AppConfigService } from "app/appconfig.service";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { VcService } from "app/shared/vc.service";
import { UserComponent } from "app/layout/common/user/user.component";
import { FhirPathService } from "app/fhirpath.service";
import { AppService } from "app/app.service";
import { VirtualClinicService } from "app/modules/virtual-clinic/virtual-clinic.service";
import { AppointmentService } from "app/modules/person/patient/health-appointment/appointment.service";

@Component({
  selector: "modern-layout",
  templateUrl: "./modern.component.html",
  styleUrls: ["./modern.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  encounter: fhir.r4.Encounter;
  appointment: fhir.r4.Appointment;
  navigation: Navigation;
  vcAvailable: boolean = false;
  preAvailable: boolean = false;
  totalVC: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() iconTpl: TemplateRef<any>;

  @ViewChild("alert", { static: true })
  public alert: IgxDialogComponent;

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    if (this.sessionEndStart) {
      this.sessionEndStart = false;
      this.alert.close();
      this.resetTimer();
    } else {
      this.resetTimer();
    }
  }

  min: number;
  sec: number;
  interval;
  subscribeTimer: any;
  sessionEndStart = false;
  username: string;
  vcLink: string;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _cadMediaWatcherService: CadMediaWatcherService,
    private _cadNavigationService: CadNavigationService,
    private _authService: AuthService,
    private _appConfigService: AppConfigService,
    private _vcService: VcService,
    private _fhirPathService: FhirPathService,
    private _appService: AppService,
    private _router: Router,
    private _virtualClinicService: VirtualClinicService,
    private _appointmentService: AppointmentService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._navigationService.vcAvailable$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((item: any) => {
        if (item === null) {
          this._appService.vcToken$.subscribe({
            next: (enc: fhir.r4.Bundle) => {
              if (enc.entry !== undefined) {
                this.encounter = this._fhirPathService.evaluate(
                  enc,
                  "entry.resource.ofType(Encounter)"
                );
                let vcToken = this._fhirPathService.evaluateToString(
                  this.encounter[0],
                  "identifier.where(system='http://fhir.hie.moh.gov.my/sid/vc-token').value"
                );
                if (vcToken != null) {
                  this.checkVcAvailable(vcToken);
                } else {
                  console.log("No VC Token");
                }
              }
            },
          });
        } else {
          this.checkVcAvailable(item.token);
        }
      });

    // this._appointmentService.checkinEnable$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((item: any) => {
    //     if (item != null) {
    //       this.preAvailable = item;
    //     }
    //   });
    this._appService.preToken$.subscribe({
      next: (app: fhir.r4.Bundle) => {
        if (app.entry !== undefined) {
          this.appointment = this._fhirPathService.evaluate(
            app,
            "entry.resource.ofType(Appointment)"
          );
          if (this.appointment) {
            if (
              this.appointment[0].serviceCategory[0].coding[0].display !==
              "Virtual"
            ) {
              this.preAvailable = false;
            } else {
              const HOUR = 1000 * 60 * 60;
              const startDate = +new Date(this.appointment[0].start);
              let currentTime = +new Date();
              let newAppointmentTime = +startDate - HOUR;
              if (
                currentTime <= startDate &&
                currentTime >= newAppointmentTime
              ) {
                if (this.appointment[0].status === "booked") {
                  this.preAvailable = true;
                } else {
                  this.preAvailable = false;
                }
              } else {
                this.preAvailable = false;
              }
            }
          } else {
            this.preAvailable = false;
          }
        } else {
          this.preAvailable = false;
        }
      },
    });

    this._appointmentService.appointmentBooked$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => { });

    this.resetTimer();
    this.sessionEndStart = false;
    this.startTimer();
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._cadMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");
      });

    setTimeout(() => {
      this.username = this._appConfigService.getUserName();
    }, 1000);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */

  checkVcAvailable(token: any) {
    this._vcService.getShow(token).subscribe({
      next: (jitsi) => {
        if (jitsi.data.status !== "ongoing") {
          this.vcAvailable = false;
        } else {
          this._virtualClinicService.triggerSomeEvent(jitsi);
          this.vcAvailable = true;
          this.vcLink = jitsi.data.participant_url;
        }
      },
    });
  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._cadNavigationService.getComponent<CadVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (!this.sessionEndStart) {
        if (
          (this.min > 0 && this.sec > 0) ||
          (this.min === 0 && this.sec > 15)
        ) {
          this.sec--;
        } else if (this.min > 0) {
          this.sec = 59;
          this.min--;
        } else if (this.min === 0 && this.sec > 0 && this.sec <= 15) {
          this.alert.open();
          this.sessionEndStart = true;
        }
      } else {
        if (this.sec > 0) {
          this.sec--;
        } else {
          this.alert.close();
          this.stopTimer();

          localStorage.clear();

          if (environment.environmentName === "PRODUCTION") {
            this._authService.logout("/portal");
          } else {
            this._authService.logout("");
          }
        }
      }
    }, 1000);
  }

  goToVc() {
    // this._router.navigate(["/apps/virtual-clinic/virtual-clinic"]);

    var popupWindow = null;
    // function centeredPopup(url,winName,w,h,scroll){
    let LeftPosition = screen.width ? (screen.width - 1400) / 2 : 0;
    let TopPosition = screen.height ? (screen.height - 900) / 2 : 0;
    let settings =
      "height=" +
      900 +
      ",width=" +
      1400 +
      ",top=" +
      TopPosition +
      ",left=" +
      LeftPosition +
      ",scrollbars=" +
      scroll +
      ",resizable";
    popupWindow = window.open(this.vcLink, "myWindow", settings);
  }

  goToPrerequisites() {
    this._router.navigate(["/apps/patient/health-appointment/", "test"]);
  }

  goToHelpdesk() {
    this._router.navigate(["/apps/helpdesk"]);
  }

  goToFaq() {
    this._router.navigate(["/apps/faq"]);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  checkEnvironment() {
    if (environment.environmentName.includes("PRODUCTION")) {
      return true;
    } else {
      return false;
    }
  }

  resetTimer() {
    this.min = this._appConfigService.getMinSession()
      ? this._appConfigService.getMinSession()
      : 6;
    this.sec = this._appConfigService.getSecSession()
      ? this._appConfigService.getSecSession()
      : 59;
  }
}
