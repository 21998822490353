export const environment = {
  production: true,
  environmentName: "QA",
  fhirURL: "https://veinscdr.mhnexus.com/baseR4",
  apiURL: "https://veinspe.mhnexus.com/",
  keycloak: {
    url: "https://veinssso.mhnexus.com",
    realm: "person",
    clientId: "person-portal",
  },
  firebase: {
    // apiKey: "AIzaSyB5nYW_MZw8kc63KjBMDbzC3A_JfMUEzVU",
    // authDomain: "hie-01.firebaseapp.com",
    // projectId: "hie-01",
    // storageBucket: "hie-01.appspot.com",
    // messagingSenderId: "191586917389",
    // appId: "1:191586917389:web:1a1acb654bc144cf0d8944",
    apiKey: "AIzaSyAG3sh2cfYrOcMZLHF6rOEhqRMCUvT6Zto",
    authDomain: "rekodsaya-6cccd.firebaseapp.com",
    projectId: "rekodsaya-6cccd",
    storageBucket: "rekodsaya-6cccd.appspot.com",
    messagingSenderId: "43054757316",
    appId: "1:43054757316:web:87653fde1004d7e703a091",
    measurementId: "G-9FFQRFYS05",
    // vapidKey:
    //   "BPtmT3bIVgizEiqzsxaoRz6hq4EVfmTj-mK3TH9vrTu_b87GvNOhdqNxcdE3jAEJKQJkZMRvUXw90Gw3LWrCA8w",
    vapidKey:
      "BAD7HKs-VEEm49zVCTrQaPYknMjegU0_YtuDfU0pGf55CBKNOu6nFhAz77WRYGEDQaJId-e8UotxDy1jQvq5Lbk",
  },
  virtualClinic: {
    url: "https://poc.portal.tarsoft.my/api/transactions",
    jwtUrl: "https://poc.jitsi.tarsoft.my/",
  },
  dreamfactory: {
    url: "https://dreamfactory.mhn.asia/api/v2",
    openId: "",
    apiKey: "b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba",
  },
  productId: "P00034874",
};
