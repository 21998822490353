import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";

import { NcrConfigService } from "app/ncrconfig.service";
@Injectable({
  providedIn: "root",
})
export class RelatedPersonService {
  private _relatedPersons: ReplaySubject<fhir.r4.RelatedPerson[]> =
    new ReplaySubject<fhir.r4.RelatedPerson[]>(1);
  private _patients: ReplaySubject<fhir.r4.Patient[]> = new ReplaySubject<
    fhir.r4.Patient[]
  >(1);
  private _patient: ReplaySubject<fhir.r4.Patient> =
    new ReplaySubject<fhir.r4.Patient>(1);
  private _banner: ReplaySubject<fhir.r4.Patient> =
    new ReplaySubject<fhir.r4.Patient>(1);

  private _records: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  get records$(): Observable<any[]> {
    return this._records.asObservable();
  }

  get relatedpersons$(): Observable<fhir.r4.RelatedPerson[]> {
    return this._relatedPersons.asObservable();
  }
  get patients$(): Observable<fhir.r4.Patient[]> {
    return this._patients.asObservable();
  }
  get patient$(): Observable<fhir.r4.Patient> {
    return this._patient.asObservable();
  }
  get banner$(): Observable<fhir.r4.Patient> {
    return this._banner.asObservable();
  }

  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _appConfigService: AppConfigService,
    private _ncrConfigService: NcrConfigService
  ) { }

  setPatient(patient: fhir.r4.Patient) {
    this._banner.next(patient);
  }

  getRealatedPersons(): Observable<fhir.r4.Bundle> {
    let id: string = this._appConfigService.getDefaultPatientId();
    let _buildFilter: string = null;

    _buildFilter = "type=keyid&id=" + this._appConfigService.getKeyId();
    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._ncrConfigService.getNcrService() +
        "/api/v1/related-person/active?" + _buildFilter
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let relatedPersons: fhir.r4.RelatedPerson[] =
            this._fhirPathService.evaluate(
              response,
              "entry.resource.ofType(RelatedPerson)"
            );
          this._relatedPersons.next(relatedPersons);
          let patients: fhir.r4.Patient[] = this._fhirPathService.evaluate(
            response,
            "entry.resource.ofType(Patient)"
          );
          this._patients.next(patients);

          let elements: any[] = [];
          let i: number = 1;
          relatedPersons.forEach((element) => {
            let patient: fhir.r4.Patient = patients.find(
              (x) => "Patient/" + x.id == element.patient.reference
            );

            let currentYear = new Date().getFullYear();
            let birthyear: number = null;

            try {
              if (patient != null) {
                if (patient.birthDate != null) {
                  birthyear = Number(patient.birthDate.substring(0, 4));
                }
              }
            } catch (e) { }


            // let birthyear: number = Number(patient.birthDate.substring(0, 4));
            let age = currentYear - birthyear;
            let status: string = "";
            let option: boolean = false;
            // if (age < 12) {
            //   status = "View record is available";
            //   option = false;
            // } else {
            //   status = "Cannot view record person 12 year and above";
            //   option = true;
            // }

            let elem: any = {
              no: i++,
              resource: element,
              lastUpdated: element.meta.lastUpdated,
              //name: patient!.name[0].text,
              //gender: patient.gender,
              relationship: element.relationship[0].coding[0].display,
              //birthdate: patient.birthDate,
              birthyear: birthyear,
              age: age,
              // status: status,
              option: option,
            };
            try {
              if (patient.name != null) {
                elem.name = patient!.name[0].text;
              }
              if (patient.gender != null) {
                elem.gender = patient!.gender;
              }
              if (patient.birthDate != null) {
                elem.birthDate = patient.birthDate;
              }
            } catch (e) {

            }
            console.log("PATIENT ", elem.birthDate);
            elements.push(elem);
          });
          this._records.next(elements);
          console.log(elements);
        })
      );
  }
  getPatient(patientId: string): Observable<fhir.r4.Patient> {
    return this._httpClient
      .get<fhir.r4.Patient>(
        this._fhirConfigService.getFhirService() + "/Patient/" + patientId
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Patient = JSON.parse(letjstring);

          this._patient.next(jsonObject);
        })
      );
  }
}
