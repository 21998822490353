import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NcrConfigService } from "app/ncrconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { Notification } from "app/layout/common/notification/notification.type";
import { AppConfigService } from "app/appconfig.service";


@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private _communications: ReplaySubject<fhir.r4.Communication[]> =
    new ReplaySubject<fhir.r4.Communication[]>(1);
  private _appointments: ReplaySubject<fhir.r4.Appointment[]> =
    new ReplaySubject<fhir.r4.Appointment[]>(1);
  private _records: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private subject = new Subject<void>();

  fhirHttpOptions = {
    headers: new HttpHeaders({
      "Cache-Control": "no-cache",
      Accept: "application/fhir+json",
    }),
  };
  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _appConfigService: AppConfigService,
  ) { }

  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  get communications$(): Observable<fhir.r4.Communication[]> {
    return this._communications.asObservable();
  }
  get appointments$(): Observable<fhir.r4.Appointment[]> {
    return this._appointments.asObservable();
  }
  get records$(): Observable<any[]> {
    return this._records.asObservable();
  }
  getAllCommunications(): Observable<fhir.r4.Bundle> {
    let _buildFilter: string = null;

    _buildFilter = "type=keyid&id=" + this._appConfigService.getKeyId();
    return this._httpClient
      .get<fhir.r4.Bundle>(
        this._ncrConfigService.getNcrService() +
        "/api/v1/communication/general?" + _buildFilter
      )
      .pipe(
        tap((response: fhir.r4.Bundle) => {
          let communications: fhir.r4.Communication[] =
            this._fhirPathService.evaluate(
              response,
              "entry.resource.ofType(Communication)"
            );
          this._communications.next(communications);
          let appointments: fhir.r4.Appointment[] =
            this._fhirPathService.evaluate(
              response,
              "entry.resource.ofType(Appointment)"
            );
          this._appointments.next(appointments);

          let items: any[] = [];
          communications.forEach((element) => {
            let title: string = null;
            let body: string = null;
            let category: string = null;

            for (let j = 0; j < element.note?.length; j++) {
              if (element.note[j]?.id == "title") title = element.note[j]?.text;
              if (element.note[j]?.id == "body") body = element.note[j]?.text;
              if (element.note[j]?.id == "category")
                category = element.note[j]?.text;
            }

            let read: boolean = null;
            if (element.status == "in-progress") read = true;
            else read = false;

            let link: string = null;
            let icon: string = null;
            if (category == "vc") {
              link = "/apps/inbox/main";
              icon = "mat_outline:videocam";
            } else if (category == "encounter-finished") {
              link = "/apps/patient/dashboard-request";
              icon = "mat_outline:local_hospital";
            } else if (category == "appointment") {
              icon = "mat_outline:edit_calendar";

              let appointmentReference = element.payload?.find(
                (x) => x.contentReference?.type === "Appointment"
              ).contentReference?.reference;
              let appointment: fhir.r4.Appointment = appointments?.find(
                (x) => "Appointment/" + x.id === appointmentReference
              );
              //532,533,534,535,536,537,538,539,540,541
              if (appointment != null) {
                let specialty: string = "";
                if (appointment?.specialty != null) {
                  specialty = appointment?.specialty[0]?.coding[0].code;
                }
                if (
                  specialty != "532" &&
                  specialty != "533" &&
                  specialty != "534" &&
                  specialty != "535" &&
                  specialty != "536" &&
                  specialty != "537" &&
                  specialty != "538" &&
                  specialty != "539" &&
                  specialty != "540" &&
                  specialty != "541"
                )
                  link = "/apps/patient/health-appointment";
                else link = "/apps/donation/blood-appointment";
              }
            } else if (category == "general") {
              link = "/apps/inbox/main";
              icon = "mat_outline:document_scanner";
            }

            let item = <any>{
              id: element.id,
              resource: element,
              status: element.status,
              useRouter: true,
              read: read,
              time: element.meta.lastUpdated,
              title: title,
              body: body,
              category: category,
              image: null,
              link: link,
              icon: icon,
            };

            if (item.title != null) {
              items.push(item);
            }

            // items.push(item);
          });
          this._records.next(items);
        })
      );
  }
  updateCommunication(
    communication: fhir.r4.Communication
  ): Observable<fhir.r4.Communication[]> {
    return this._httpClient
      .put<fhir.r4.Communication[]>(
        this._fhirConfigService.getFhirService() +
        "/Communication/" +
        communication.id,
        communication
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
        })
      );
  }

  postBundle(bundle: fhir.r4.Bundle): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .post<fhir.r4.Bundle>(this._fhirConfigService.getFhirService(), bundle)
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
        })
      );
  }

  // update(query:fhir.r4.Communication): Observable<fhir.r4.Communication>
  // {
  //     return this.communications$.pipe(
  //         take(1),
  //         switchMap(communications => this._httpClient.put<fhir.r4.Communication>(this._fhirConfigService.getFhirService() +"/Communication/"+query.id,query).pipe(
  //             map((updatedNotification: fhir.r4.Communication) => {

  // 			const index = communications.findIndex(item => item.id === query.id);
  // 			communications[index] = updatedNotification;
  //             this._communications.next(communications);
  //                 return updatedNotification;
  //             })
  //         ))
  //     );
  // }
}
