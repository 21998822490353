<div
  class="w-full p-5 bg-fuchsia-800 text-white font-bold text-lg uppercase"
  *ngIf="user"
>
  CURRENT USER : {{ user }}
</div>
<div>
<div
 
  *ngIf="!fcmToken"
>
<div  class="w-full pl-10 pt-2 pb-2 bg-red-800">
<a class="pt-2 text-white h-full" href="https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop" target="_blank"><mat-icon class="text-white" [svgIcon]="'heroicons_outline:information-circle'"></mat-icon></a>

<a class="pl-2 h-full text-center text-white font-bold text-lg uppercase" >     {{ "enableNotification" | transloco }}
</a>
 

</div>


</div>

</div>

