import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver, YourCustomResolver } from "app/app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/project'
  { path: "", pathMatch: "full", redirectTo: "apps/patient/dashboard-request" },

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "apps/patient/dashboard-request" },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("app/modules/cad/landing/home/home.module").then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "portal/RPP/MY",
        children: [
          {
            path: "Invoice",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/invoice/invoice.module").then(
                (m) => m.InvoiceModule
              ),
          },
          {
            path: "BankList",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/bank-list/bank-list.module").then(
                (m) => m.BankListModule
              ),
          },
          {
            path: "Redirect/RTP",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/receipt/receipt.module").then(
                (m) => m.ReceiptModule
              ),
          },
        ],
      },
      {
        path: "RPP/MY",
        children: [
          {
            path: "Invoice",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/invoice/invoice.module").then(
                (m) => m.InvoiceModule
              ),
          },
          {
            path: "BankList",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/bank-list/bank-list.module").then(
                (m) => m.BankListModule
              ),
          },
          {
            path: "Redirect/RTP",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/rpp/receipt/receipt.module").then(
                (m) => m.ReceiptModule
              ),
          },
        ],
      },
      {
        path: "apps",
        children: [
          {
            path: "patient",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/person/patient/patient.module").then(
                (m) => m.PatientModule
              ),
          },
          {
            path: "facility",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/facility/facility.module").then(
                (m) => m.FacilityModule
              ),
          },
          {
            path: "vc-on-demand",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/vc-on-demand/vc-on-demand.module").then(
                (m) => m.VcOnDemandModule
              ),
          },
          {
            path: "consent",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/person/user/consent/consent.module").then(
                (m) => m.UserConsentModule
              ),
          },
          {
            path: "home",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/person/home/home.module").then(
                (m) => m.HomeModule
              ),
          },
          {
            path: "donation",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/donation/donation.module").then(
                (m) => m.DonationModule
              ),
          },
          {
            path: "pink-book",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/pink-book/pink-book.module").then(
                (m) => m.PinkBookModule
              ),
          },
          {
            path: "child-book",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/child-book/child-book.module").then(
                (m) => m.ChildBookModule
              ),
          },
          {
            path: "kkm-portal",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/kkm-portal/kkm-portal.module").then(
                (m) => m.KKMPortalModule
              ),
          },
          {
            path: "inbox",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/inbox/inbox.module").then(
                (m) => m.InboxModule
              ),
          },
          {
            path: "virtual-clinic",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/virtual-clinic/virtual-clinic.module").then(
                (m) => m.VirtualClinicModule
              ),
          },
          {
            path: "related-person",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import(
                "app/modules/person/user/related-person/related-person.module"
              ).then((m) => m.RelatedPersonModule),
          },
          {
            path: "helpdesk",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/helpdesk/helpdesk.module").then(
                (m) => m.HelpdeskModule
              ),
          },
          {
            path: "faq",
            canActivate: [AuthGuard],
            loadChildren: () =>
              import("app/modules/faq/faq.module").then(
                (m) => m.FaqModule
              ),
          },
        ],
      },

      // Pages
      {
        path: "pages",
        children: [
          // Coming Soon
          {
            path: "coming-soon",
            loadChildren: () =>
              import(
                "app/modules/cad/pages/coming-soon/coming-soon.module"
              ).then((m) => m.ComingSoonModule),
          },
          // Error
          {
            path: "error",
            children: [
              {
                path: "404",
                loadChildren: () =>
                  import(
                    "app/modules/cad/pages/error/error-404/error-404.module"
                  ).then((m) => m.Error404Module),
              },
              {
                path: "500",
                loadChildren: () =>
                  import(
                    "app/modules/cad/pages/error/error-500/error-500.module"
                  ).then((m) => m.Error500Module),
              },
            ],
          },
          // Maintenance
          {
            path: "maintenance",
            loadChildren: () =>
              import(
                "app/modules/cad/pages/maintenance/maintenance.module"
              ).then((m) => m.MaintenanceModule),
          },
        ],
      },

      // 404 & Catch all
      {
        path: "404-not-found",
        pathMatch: "full",
        loadChildren: () =>
          import("app/modules/cad/pages/error/error-404/error-404.module").then(
            (m) => m.Error404Module
          ),
      },
      { path: "**", redirectTo: "404-not-found" },
    ],
  },
];
