import { environment } from "environments/environment";
import { FhirConfigService } from "./fhirconfig.service";
import { NcrConfigService } from "./ncrconfig.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
} from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { AppService } from "./app.service";
import moment, { Moment } from "moment";
import { NotificationService } from "app/layout/common/notification/notification.service";
import { NotificationComponent } from "app/layout/common/notification/notification.component";
import { Subject, takeUntil } from "rxjs";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AppConfigService } from "app/appconfig.service";
import { AppointmentService } from "app/modules/person/patient/health-appointment/appointment.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  title = "af-notification";
  message: any = null;
  devices: any[];
  fcmtoken: any;

  ngOnInit(): void {
    this.currentUser();
    this.requestPermission();
    this.listen();
    this.getVcToken();
  }

  //PUSH NOTIFICATION START
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.fcmtoken = currentToken;
          this.registerGeneralTopic(currentToken);
          this._appConfigService.changeFCMToken(currentToken);
          this._appService
            .getDevices(currentToken)
            .subscribe((response) => this.registerFCM(response));
        } else {
        }
      })
      .catch((err) => {
        this._appConfigService.changeFCMToken("");
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      //ioutput
      this.message = payload;
      this._notificationService.sendClickEvent();
      this._notificationService.getAllCommunications().subscribe((resp) => {
        this.getVcToken();
        this._appointmentService.getAppointmentBooked().subscribe();
        this._appointmentService.getAppointmentFullfilled().subscribe();
        this._appointmentService.getAppointmentCancelled().subscribe();
      });
    });

    window.addEventListener("focus", (event: Event) => {
      this._notificationService.sendClickEvent();
      this._notificationService.getAllCommunications().subscribe((resp) => {
        this.getVcToken();
        this._appointmentService.getAppointmentBooked().subscribe();
        this._appointmentService.getAppointmentFullfilled().subscribe();
        this._appointmentService.getAppointmentCancelled().subscribe();
      });
    });
  }
  //register token to general topic
  registerGeneralTopic(token: string) {
    if (
      environment.environmentName == "DEV" ||
      environment.environmentName == "QA" ||
      environment.environmentName == "STAGING"
    ) {
      let json = {
        topic: "general-qa",
        tokens: [token],
      };
      this._appService.subscribeDeviceToTopic(json).subscribe((response) => { });
    } else if (environment.environmentName == "PRODUCTION") {
      let json = {
        topic: "general",
        tokens: [token],
      };
      this._appService.subscribeDeviceToTopic(json).subscribe((response) => { });
    }
  }
  //check condition token to update or create
  registerFCM(response: any) {
    if (response.data.AFMDevice.edges.length > 0) {
      for (let i = 0; i < response.data.AFMDevice.edges.length; i++) {
        if (response.data.AFMDevice.edges[i].node.idiom === "browser") {
          this.replaceQuery(
            this.fcmtoken,
            response.data.AFMDevice.edges[0].node.id,
            response.data.AFMDevice.edges[i].node.insertDate
          );
        }
      }
    } else {
      this.mutationQuery(this.fcmtoken); //CREATE IF TOKEN NOT IN DB
    }
  }
  //save current user profile
  async currentUser() {
    const userProfile = await this._authService.loadUserProfile();
    if (userProfile.id) this._appConfigService.changeKeyId(userProfile.id);
    if (userProfile["attributes"].patientResourceId) {
      this._appConfigService.changePatientResourceId(
        userProfile["attributes"].patientResourceId[0]
      );
      this._appConfigService.changeDefaultPatientResourceId(
        userProfile["attributes"].patientResourceId[0]
      );
    } else {
      this._appConfigService.removePatientResourceId();
      // this._appConfigService.changePatientResourceId(null
      // );
      // this._appConfigService.changeDefaultPatientResourceId(null
      // );
    }
    if (userProfile["attributes"].idNo) {
      this._appConfigService.changePatientId(userProfile["attributes"].idNo[0]);
      this._appConfigService.changeDefaultPatientId(
        userProfile["attributes"].idNo[0]
      );
    }
    if (userProfile != null) {
      this._notificationService.getAllCommunications().subscribe();
    }
  }
  //create token in cad
  async mutationQuery(token: string) {
    let idNo: string = this._appConfigService.getPatientId();
    let patientId: string = this._appConfigService.getPatientResourceId();
    let keyId: string = this._appConfigService.getKeyId();

    let datte = new Date();
    this._appService.createDevices(token, keyId, idNo, patientId).subscribe();
  }
  //update token in cad
  async replaceQuery(token: string, id: string, insertDate: string) {
    let idNo: string = this._appConfigService.getPatientId();
    let patientId: string = this._appConfigService.getPatientResourceId();
    let keyId: string = this._appConfigService.getKeyId();
    let datte = new Date();
    this._appService
      .updateDevices(id, insertDate, token, keyId, idNo, patientId)
      .subscribe();
  }

  //Get VC Token from Encounter
  async getVcToken() {
    let patientId: string = this._appConfigService.getPatientResourceId();
    if (patientId != null)
      this._appService.getLatestEncounter(patientId).subscribe();
    this._appService.getLatestAppointment(patientId).subscribe();
  }

  //private tabWasClosed = false;//
  private accessToken: string;
  private refreshToken: string;

  //@HostListener('window:beforeunload', ['$event'])
  doBeforeUnload() {
    //if (document.visibilityState === 'hidden') {
    this.accessToken = this._authService.accessToken();
    this.refreshToken = this._authService.refreshToken();

    //this.tabWasClosed = true;
    //}
    //return false;
  }

  //@HostListener('window:unload', ['$event'])
  doUnload() {
    //if (this.tabWasClosed) {
    if (environment.production) {
      this.checkHiddenDocument();
    }
    //}
  }

  private checkHiddenDocument() {
    if (!navigator.sendBeacon) return true;

    var data = {
      id: environment.keycloak.clientId,
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
    };
    let headers = {
      type: "application/json",
    };
    let blob = new Blob([JSON.stringify(data)], headers);
    this._authService.clearToken();

    navigator.sendBeacon(
      this._ncrConfigService.getNcrService() + "/api/v1/auth/logout",
      blob
    );
  }

  /**
   * Constructor
   */
  constructor(
    private _fhirConfigService: FhirConfigService,
    private _ncrConfigService: NcrConfigService,
    private _authService: AuthService,
    private _appService: AppService,
    private _notificationService: NotificationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _appConfigService: AppConfigService,
    private _appointmentService: AppointmentService
  ) {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
      console.error = function (): void { };
    }

    this._appConfigService.changeDateFormat("dd-MMM-yyyy");
    this._appConfigService.changeDateTimeFormat("dd-MMM-yyyy HH:mm");
    this._fhirConfigService.changeFhirService(environment.fhirURL);
    this._ncrConfigService.changeNcrService(environment.apiURL);
    this._appConfigService.changeDreamService(environment.dreamfactory.url);
    this._appConfigService.changeUser("false"
    );
    window.localStorage.setItem("read_url", environment.apiURL);
  }
}
//
