
import { Subject,BehaviorSubject,map, Observable, ReplaySubject, switchMap, take, tap, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import { ReferenceCode } from 'app/shared/ReferenceCode';
import { NcrConfigService } from 'app/ncrconfig.service';

const httpOptions = {
	headers: new HttpHeaders({
	  "Content-Type": "application/json",
	}),
  };

@Injectable({
    providedIn: "root",
})
export class VirtualClinicService {
    /**
     * Constructor
     */

	public eventSubject = new BehaviorSubject<any>(null);

    constructor(
        private _httpClient: HttpClient,
    ) {
    }

	triggerSomeEvent(item) {
		this.eventSubject.next(item);
	  }

	getEventSubject(): BehaviorSubject<any> {
		return this.eventSubject;
	  }


}
