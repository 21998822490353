import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CadVerticalNavigationComponent } from '@cad/components/navigation/vertical/vertical.component';
import { CadNavigationService } from '@cad/components/navigation/navigation.service';
import { CadNavigationItem } from '@cad/components/navigation/navigation.types';
import { CadUtilsService } from '@cad/services/utils/utils.service';
import { AppConfigService } from 'app/appconfig.service';

@Component({
    selector       : 'cad-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CadVerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
    @Input() item: CadNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _cadVerticalNavigationComponent: CadVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _cadNavigationService: CadNavigationService,
        private _cadUtilsService: CadUtilsService,
        private _appConfigService: AppConfigService,
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._cadUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option

        window.addEventListener("storage", (event) => {
            console.log("JADI yeyeyey");

            this.updateItemDisabled();
        });

        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._cadUtilsService.exactMatchOptions
                : this._cadUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._cadVerticalNavigationComponent = this._cadNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._cadVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public updateItemDisabled() : void {
        let patientAge = this._appConfigService.getPatientAge();
        let age = +patientAge;
        if (this.item.title == 'my-blood-donation') {
            if (age < 18) {
                console.log("disabled")
                this.item.disabled = true;
            } else {
                this.item.disabled = false; // Reset to false if age is >= 18
            }

            // Uncomment the next line if necessary.
            this._changeDetectorRef.detectChanges();
        }
    }
}
