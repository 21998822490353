import { NcrConfigService } from "app/ncrconfig.service";
import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import moment, { Moment } from "moment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  private _fcmtoken: ReplaySubject<any> = new ReplaySubject<any>(1);
  get fcmtoken$(): Observable<any> {
    return this._fcmtoken.asObservable();
  }
  private _devices: ReplaySubject<any> = new ReplaySubject<any>(1);
  get devices$(): Observable<any> {
    return this._devices.asObservable();
  }

  private _vcToken: ReplaySubject<any> = new ReplaySubject<any>(1);
  get vcToken$(): Observable<any> {
    return this._vcToken.asObservable();
  }

  private _preToken: ReplaySubject<any> = new ReplaySubject<any>(1);
  get preToken$(): Observable<any> {
    return this._preToken.asObservable();
  }

  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService
  ) { }

  getDevices(token: any): Observable<any> {
    const query =
      `{
            AFMDevice(filter:"deviceToken=='` +
      token +
      `'") {
              edges {
                node {
                      id
                      deviceToken
                      keycloakId
                      identifier
                      idiom
                      version
                      manufacturer
                      modelName
                      platform
                      insertDate
                      updateDate
                }
              }
            }
          }`;
    return this._httpClient
      .post<Observable<any>>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        this.httpOptions
      )
      .pipe(
        tap((response) => {
          this._devices.next(response);
          this._fcmtoken.next(token);
        })
      );
  }

  createDevices(
    fcmtoken: string,
    keycloakId: string,
    patientId: string,
    patientResourceId: string
  ): Observable<any> {
    let date = new Date();
    const query =
      `mutation{
          AFMDevice(op: UPSERT, data: 
          {
            deviceToken: "` +
      fcmtoken +
      `"
            keycloakId: "` +
      keycloakId +
      `"
            identifier: "` +
      patientId +
      `"
            patientId:"` +
      patientResourceId +
      `"
            insertDate:"` +
      moment(date).format("YYYY-MM-DDTHH:mm") +
      "Z" +
      `"
            updateDate:"` +
      moment(date).format("YYYY-MM-DDTHH:mm") +
      "Z" +
      `"
            version:"Browser Version"
            idiom:"browser"
            modelName:"chrome"
            deviceType:"laptop"
            platform:"window"
            manufacturer:"apple"
          }) {
                edges {
                    node {
                        id
                      deviceToken
                      keycloakId
                      identifier
                      idiom
                      version
                      manufacturer
                      modelName
                      platform
                      insertDate
                      updateDate
                    }
                  }
                }
        }`;
    return this._httpClient
      .post<Observable<any>>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        this.httpOptions
      )
      .pipe(
        tap((response) => {
          this._devices.next(response);
          let json = {
            topic: "general",
            tokens: [fcmtoken],
          };
          this.subscribeDeviceToTopic(json).subscribe();
        })
      );
  }
  updateDevices(
    id: string,
    insertDate: string,
    fcmtoken: string,
    keycloakId: string,
    patientId: string,
    patientResourceId: string
  ): Observable<any> {
    let date = new Date();
    const query =
      `mutation{
            AFMDevice(op: UPSERT, data: 
            {
                id:"` +
      id +
      `"
                deviceToken: "` +
      fcmtoken +
      `"
                keycloakId: "` +
      keycloakId +
      `"
                identifier: "` +
      patientId +
      `"
                patientId:"` +
      patientResourceId +
      `"
                insertDate:"` +
      insertDate +
      `"
                updateDate:"` +
      moment(date).format("YYYY-MM-DDTHH:mm") +
      "Z" +
      `"
                version:"Browser Version"
                idiom:"browser"
                modelName:"chrome"
                deviceType:"laptop"
                platform:"window"
                manufacturer:"apple"
            }) {
                  edges {
                      node {
                        id
                        deviceToken
                        keycloakId
                        identifier
                        idiom
                        version
                        manufacturer
                        modelName
                        platform
                        insertDate
                        updateDate
                      }
                    }
                  }
          }`;
    return this._httpClient
      .post<Observable<any>>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        this.httpOptions
      )
      .pipe(
        tap((response) => {
          this._devices.next(response);
          let json = {
            topic: "general",
            tokens: [fcmtoken],
          };
          this.subscribeDeviceToTopic(json).subscribe();
        })
      );
  }

  subscribeDeviceToTopic(query: any): any {
    const opts: any = {
      responseType: "text",
    };
    return this._httpClient.post<string>(
      this._ncrConfigService.getNcrService() +
      "/api/v1/notification/subscribe-topic-by-list-token",
      query,
      opts
    );
  }

  getLatestEncounter(patientResourceId: any) {
    let date = new Date();
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Encounter?subject:mdm=Patient/" +
        patientResourceId +
        "&_sort=-date&_count=1&status=arrived,in-progress&_include:iterate=Encounter:appointment&date-start=ge" +
        moment(date).format("YYYY-MM-DD")
      )
      .pipe(
        tap((response) => {
          this._vcToken.next(response);
        })
      );
  }

  fhirHttpOptions = {
    headers: new HttpHeaders({
      "Cache-Control": "no-cache",
      Accept: "application/fhir+json",
    }),
  };
  getLatestAppointment(patientResourceId: any) {
    return this._httpClient
      .get(
        this._fhirConfigService.getFhirService() +
        "/Appointment?actor=" +
        patientResourceId +
        "&service-category=VR&status=booked&_sort=-date&_count=1",
        this.fhirHttpOptions
      )
      .pipe(
        tap((response) => {
          this._preToken.next(response);
        })
      );
  }
}
