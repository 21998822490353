import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { BooleanInput } from "@angular/cdk/coercion";
import { User } from "app/core/user/user.types";
import { AuthService } from "app/core/auth/auth.service";
import { AppConfigService } from "app/appconfig.service";
import { KeycloakProfile } from "keycloak-js";
import { TranslocoService } from "@ngneat/transloco";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import * as Sentry from "@sentry/angular";
import { environment } from "environments/environment";
import { UserRequestService } from "app/layout/common/user/user-request.service";
import { FhirPathService } from "app/fhirpath.service";
import moment from "moment";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit {
  rating: number;
  starCount: number = 5;
  ratingArr = [];
  hideEdit: boolean = true;
  patient: any = {
    source: null,
    rating: 0,
    remark: "no remark",
  };
  counter(i: number) {
    return new Array(i);
  }

  showIcon(index: number, rate) {
    if (rate >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  displayRate(value) {
    if (value == 0) return this.translation["no-review"];
    if (value == 1) return this.translation["very-poor"];
    if (value == 2) return this.translation["poor"];
    if (value == 3) return this.translation["average"];
    if (value == 4) return this.translation["good"];
    if (value == 5) return this.translation["very-good"];
  }

  updateRating(patient, rateValue, remarkValue) {
    let tempExten: any[] = [];
    let rateExten: any[] = [];
    for (let i = 0; i < patient.extension.length; i++) {
      if (
        patient.extension[i].url ==
        "http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core"
      ) {
        rateExten.push(patient.extension[i]);
      } else {
        tempExten.push(patient.extension[i]);
      }
    }

    let newExten: any[] = [];
    newExten = tempExten;

    if (rateExten.length > 0) {
      let star: any[] = [];
      let rateExt = rateExten[0].extension;
      let remark = null;
      let rate = null;
      let rateDate = null;
      if (remarkValue == null) {
        remark = rateExt.find((x) => x.url == "remark");
        if (remark != null) {
          // star.push(remark);
        }
      } else {
        remark = {
          url: "remark",
          valueString: remarkValue,
        };
        // star.push(remark);
      }
      if (rateValue == null) {
        rate = rateExt.find((x) => x.url == "rating");
        rateDate = rateExt.find((x) => x.url == "lastUpdated");
        star.push(rate);
        star.push(rateDate);
      } else {
        rate = {
          url: "rating",
          valueCodeableConcept: {
            coding: [
              {
                system: "http://fhir.hie.moh.gov.my/CodeSystem/rating-my-core",
                code: rateValue.toString(),
                display: this.displayRate(rateValue),
              },
            ],
          },
        };
        rateDate = {
          url: "lastUpdated",
          valueDateTime: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss+08:00"),
        };
        star.push(rate);
        star.push(rateDate);
      }
      //update

      //create new
      let exten: any = <any>{
        url: "http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core",
        extension: star,
      };
      newExten.push(exten);
    } else {
      //create new
      let star: any[] = [];
      let rate = null;
      let rateDate = null;
      let remark = null;
      if (rateValue != null) {
        rate = {
          url: "rating",
          valueCodeableConcept: {
            coding: [
              {
                system: "http://fhir.hie.moh.gov.my/CodeSystem/rating-my-core",
                code: rateValue.toString(),
                display: this.displayRate(rateValue),
              },
            ],
          },
        };
        star.push(rate);
        rateDate = {
          url: "lastUpdated",
          valueDateTime: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss+08:00"),
        };
        star.push(rateDate);
      }
      if (remarkValue != null) {
        remark = {
          url: "remark",
          valueString: remarkValue,
        };
        // star.push(remark);
      }

      let exten: any = <any>{
        url: "http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core",
        extension: star,
      };
      newExten.push(exten);
    }
    patient.extension = newExten;
    this._userRequestService
      .updatePatient(patient.id, patient)
      .subscribe((val) => {
        this._userRequestService.getPatient().subscribe((item) => {
          this.patient = {
            source: item,
            rating: Number(
              this._fhirPathService.evaluateToString(
                item,
                "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='rating').valueCodeableConcept.coding.code"
              )
            ),
            remark: this._fhirPathService.evaluateToString(
              item,
              "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='remark').valueString"
            ),
            rateDate: this._fhirPathService.evaluateToString(
              item,
              "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='lastUpdated').valueDateTime"
            ),
          };
          this._changeDetectorRef.detectChanges();
        });
      });
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = false;
  user: User;

  translation: any = {
    "no-review": "",
    "very-poor": "",
    poor: "",
    average: "",
    good: "",
    "very-good": "",
  };

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _appConfigService: AppConfigService,
    private _keycloakService: KeycloakService,
    private TranslocoService: TranslocoService,
    private _userRequestService: UserRequestService,
    private _fhirPathService: FhirPathService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    const userProfile: KeycloakProfile =
      await this._authService.loadUserProfile();
    this.user = {
      avatar: "",
      email: userProfile.email,
      name: userProfile.firstName,
      id: userProfile.id,
      status: "online",
    };

    if (
      environment.environmentName === "QA" ||
      environment.environmentName === "STAGING"
    ) {
      Sentry.setUser({ username: userProfile.username });
    }

    this._appConfigService.changeUserName(userProfile.firstName);
    this._appConfigService.changePatientEmail(userProfile.email);
    this._appConfigService.changeFullName(
      userProfile.firstName + " " + userProfile.lastName
    );
    this._appConfigService.changeEmail(userProfile.email);

    if (userProfile["attributes"].patientResourceId) {
      this._appConfigService.changePatientResourceId(
        userProfile["attributes"].patientResourceId[0]
      );
      this._appConfigService.changeDefaultPatientResourceId(
        userProfile["attributes"].patientResourceId[0]
      );
    } else {
      this._appConfigService.removePatientResourceId();
      // this._appConfigService.changePatientResourceId(null
      // );
      // this._appConfigService.changeDefaultPatientResourceId(null
      // );
    }
    if (userProfile["attributes"].idNo) {
      this._appConfigService.changePatientId(userProfile["attributes"].idNo[0]);
      this._appConfigService.changeDefaultPatientId(
        userProfile["attributes"].idNo[0]
      );
    }
    if (userProfile["attributes"].idType) {
      this._appConfigService.changeIdType(userProfile["attributes"].idType[0]);
    }
    if (userProfile["attributes"].mobileNo) {
      this._appConfigService.changePhone(userProfile["attributes"].mobileNo[0]);
    }
    if (userProfile.email) {
      this._appConfigService.changeEmail(userProfile.email);
    }
    if (userProfile.firstName && userProfile.lastName) {
      this._appConfigService.changeFullName(
        userProfile.firstName + " " + userProfile.lastName
      );
    }

    this._userRequestService.getPatient().subscribe((item) => {
      this._appConfigService.patientGender(
        this._fhirPathService.evaluateToString(item, "gender")
      );
      this.patient = {
        source: item,
        rating: Number(
          this._fhirPathService.evaluateToString(
            item,
            "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='rating').valueCodeableConcept.coding.code"
          )
        ),
        remark: this._fhirPathService.evaluateToString(
          item,
          "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='remark').valueString"
        ),
        rateDate: this._fhirPathService.evaluateToString(
          item,
          "extension.where(url='http://fhir.hie.moh.gov.my/StructureDefinition/system-rating-my-core').extension.where(url='lastUpdated').valueDateTime"
        ),
      };
      this._changeDetectorRef.detectChanges();
    });

    this._keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          this._keycloakService
            .updateToken(10)
            .then(function (refreshed) {
              if (refreshed) {
                console.log("Token was successfully refreshed");
              } else {
                console.log("Token is still valid");
              }
            })
            .catch(async function () {
              if (environment.environmentName === "PRODUCTION") {
                await this._keycloakService.login({
                  redirectUri: window.location.origin + "/portal",
                });
              } else {
                await this._keycloakService.login({
                  redirectUri: window.location.origin,
                });
              }
            });
        }
      },
    });

    this._keycloakService.keycloakEvents$.subscribe({
      next: async (e) => {
        if (
          e.type === KeycloakEventType.OnAuthRefreshError ||
          e.type === KeycloakEventType.OnAuthLogout ||
          e.type === KeycloakEventType.OnAuthError
        ) {
          console.log(
            "Failed to refresh the token, or the session has expired"
          );
          if (environment.environmentName === "PRODUCTION") {
            await this._keycloakService.login({
              redirectUri: window.location.origin + "/portal",
            });
          } else {
            await this._keycloakService.login({
              redirectUri: window.location.origin,
            });
          }
        }
      },
    });

    this.TranslocoService.selectTranslation().subscribe((translate) => {
      this.translation["no-review"] = translate["no-review"];
      this.translation["very-poor"] = translate["very-poor"];
      this.translation["poor"] = translate["poor"];
      this.translation["average"] = translate["average"];
      this.translation["good"] = translate["good"];
      this.translation["very-good"] = translate["very-good"];
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    if (environment.environmentName === "PRODUCTION") {
      this._authService.logout("/portal");
    } else {
      this._authService.logout("");
    }
    localStorage.clear();
  }

  goToConsent(): void {
    this._router.navigate(["apps/consent"]);
  }
  goToRelatedPerson(): void {
    this._router.navigate(["apps/related-person"]);
  }

  goToProfile(): void {
    this._authService.redirectToProfile();
  }
}
