import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { UserComponent } from "app/layout/common/user/user.component";
import { SharedModule } from "app/shared/shared.module";
import { RouterModule } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import {
  IgxButtonModule,
  IgxDialogModule,
  IgxRippleModule,
  IgxTooltipModule,
  IgxIconModule,
} from "@infragistics/igniteui-angular";

@NgModule({
  declarations: [UserComponent],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    RouterModule,
    TranslocoModule,
    IgxButtonModule,
    IgxDialogModule,
    IgxRippleModule,
    IgxTooltipModule,
    IgxIconModule,
  ],
  exports: [UserComponent],
})
export class UserModule {}
