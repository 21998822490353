<div
    class="cad-vertical-navigation-item-wrapper"
    [class.cad-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <div
        class="cad-vertical-navigation-item"
        [ngClass]="{'cad-vertical-navigation-item-active': active,
                    'cad-vertical-navigation-item-disabled': item.disabled,
                    'cad-vertical-navigation-item-active-forced': item.active}"
        [matTooltip]="item.tooltip || ''">

        <!-- Icon -->
        <ng-container *ngIf="item.icon">
            <mat-icon
                class="cad-vertical-navigation-item-icon"
                [ngClass]="item.classes?.icon"
                [svgIcon]="item.icon"></mat-icon>
        </ng-container>

        <!-- Title & Subtitle -->
        <div class="cad-vertical-navigation-item-title-wrapper">
            <div class="cad-vertical-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    <ng-container *transloco="let t">
                        {{ t(item.title) }}
                    </ng-container>
                </span>
            </div>
            <ng-container *ngIf="item.subtitle">
                <div class="cad-vertical-navigation-item-subtitle">
                    <span [ngClass]="item.classes?.subtitle">
                        <ng-container *transloco="let t">
                            {{ t(item.subtitle) }}
                        </ng-container>
                    </span>
                </div>
            </ng-container>
        </div>

    </div>

</div>

<ng-container *ngIf="!skipChildren">

    <div class="cad-vertical-navigation-item-children">

        <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <cad-vertical-navigation-basic-item
                        [item]="item"
                        [name]="name"></cad-vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <cad-vertical-navigation-collapsable-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></cad-vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <cad-vertical-navigation-divider-item
                        [item]="item"
                        [name]="name"></cad-vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group'">
                    <cad-vertical-navigation-group-item
                        [item]="item"
                        [name]="name"></cad-vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <cad-vertical-navigation-spacer-item
                        [item]="item"
                        [name]="name"></cad-vertical-navigation-spacer-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>

</ng-container>
