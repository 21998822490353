<button
  mat-icon-button
  matTooltip="User Profile"
  [matMenuTriggerFor]="userActions"
>
  <span class="relative">
    <img class="w-7 h-7 rounded-full" *ngIf="showAvatar" [src]="user.avatar" />
    <mat-icon
      *ngIf="!showAvatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span
        ><ng-container *transloco="let t">
          {{ t("signed-in-as") }}
        </ng-container></span
      >
      <span *ngIf="user" class="mt-1.5 text-md font-medium">{{
        user.name
      }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="goToProfile()">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span
      ><ng-container *transloco="let t">
        {{ t("kkmonelogin-console") }}
      </ng-container></span
    >
  </button>
  <button mat-menu-item (click)="goToConsent()">
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span
      ><ng-container *transloco="let t">
        {{ t("privacy-consent") }}
      </ng-container></span
    >
  </button>
  <button mat-menu-item (click)="goToRelatedPerson()">
    <mat-icon [svgIcon]="'heroicons_outline:two_person'"></mat-icon>
    <span
      ><ng-container *transloco="let t">
        {{ t("switch-user") }}
      </ng-container></span
    >
  </button>
  <button mat-menu-item (click)="systemRate.open()" *transloco="let t">
    <mat-icon [svgIcon]="'mat_outline:star_rate'"></mat-icon>
    <span
      ><ng-container> {{ t("rate-us") }}</ng-container></span
    >
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span
      ><ng-container *transloco="let t">
        {{ t("sign-out") }}
      </ng-container></span
    >
  </button>
</mat-menu>

<igx-dialog
  *transloco="let t"
  #alert
  [title]="t('are-you-still-there')"
  [message]="t('close-session')"
  [leftButtonLabel]="t('ok')"
  (leftButtonSelect)="alert.close()"
>
</igx-dialog>

<igx-dialog #systemRate [closeOnOutsideSelect]="false" style="width: 500px">
  <igx-dialog-title>
    <div
      class="dialog-container flex flex-row justify-between w-full"
      *transloco="let t"
    >
      <div class="dialog-title">{{ t("system-rating") }}</div>
      <div>
        <igx-icon (click)="systemRate.close()">close</igx-icon>
      </div>
    </div>
  </igx-dialog-title>
  <div class="flex flex-col">
    <div class="flex flex-row">
      <button
        *ngFor="let in of counter(5); let i = index"
        (click)="updateRating(patient.source, i + 1, null)"
      >
        <mat-icon
          class="z-10"
          [ngStyle]="{ color: '#FFD700' }"
          [igxTooltipTarget]="tooltipRef"
        >
          {{ showIcon(i, patient.rating) }}</mat-icon
        >

        <div #tooltipRef="tooltip" igxTooltip>{{ displayRate(i + 1) }}</div>
      </button>
    </div>
    <div>
      last update rating :
      {{ patient.rateDate | date : "dd-MMM-YYYY HH:mm:ss" }}
    </div>
    <!-- <div *transloco="let t">{{ t("add-your-suggestion-and-feedback") }}</div>

    <br />
    <textarea
      #remarkValue
      value="{{ patient.remark }}"
      type="text"
      class="bg-gray-50 h-20 w-100 text-black"
      width="100%"
    ></textarea>
    <div>
      <button
        igxButton="raised"
        igxRipple="rgb(238 242 255)"
        class="medical-record-raised-btn mt-4"
        *transloco="let t"
        displayDensity="comfortable"
        (click)="
          updateRating(patient.source, null, remarkValue.value);
          systemRate.close()
        "
      >
        {{ t("save") }}
      </button>
    </div> -->
  </div>
</igx-dialog>
