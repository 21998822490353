import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BannerComponent } from 'app/layout/common/banner/banner.component';
import { SharedModule } from 'app/shared/shared.module';
import { TranslocoModule } from "@ngneat/transloco";
@NgModule({
    declarations: [
        BannerComponent
    ],
    imports     : [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        SharedModule,
        TranslocoModule
    ],
    exports     : [
        BannerComponent
    ]
})
export class BannerModule
{
}
