<!-- Item wrapper -->
<div
    class="cad-horizontal-navigation-item-wrapper"
    [class.cad-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <div
            class="cad-horizontal-navigation-item"
            [ngClass]="{'cad-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'cad-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
            class="cad-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
            class="cad-horizontal-navigation-item"
            [ngClass]="{'cad-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <div
            class="cad-horizontal-navigation-item"
            [ngClass]="{'cad-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'cad-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
            class="cad-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)"
            mat-menu-item>
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
            class="cad-horizontal-navigation-item"
            [ngClass]="{'cad-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div class="cad-horizontal-navigation-item cad-horizontal-navigation-item-disabled" (click)="bloodDonation.open()">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
        <mat-icon
            class="cad-horizontal-navigation-item-icon"
            [ngClass]="item.classes?.icon"
            [svgIcon]="item.icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="cad-horizontal-navigation-item-title-wrapper">
        <div class="cad-horizontal-navigation-item-title">
            <span [ngClass]="item.classes?.title">
                <ng-container *transloco="let t">
                    {{ t(item.title) }}
                </ng-container>
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="cad-horizontal-navigation-item-subtitle text-hint">
                <span [ngClass]="item.classes?.subtitle">
                    <ng-container *transloco="let t">
                        {{ t(item.subtitle) }}
                    </ng-container>
                </span>
            </div>
        </ng-container>
    </div>

</ng-template>

<igx-dialog #bloodDonation class="w-3/5">
    <p class="text-xl font-semibold text-black">
      <ng-container *transloco="let t">
        {{ t("age-restriction") }}
      </ng-container>
    </p>
    <div class="mt-4 text-black text-justify">
      <p>
        <ng-container *transloco="let t">
            {{ t("above-17-years-old") }}
        </ng-container>
      </p>
    </div>
    <div igxDialogActions>
      <button mat-button color="primary" (click)="bloodDonation.close()">
        OK
      </button>
    </div>
</igx-dialog>
