import { FormsModule } from "@angular/forms";
import { MbscModule } from "@mobiscroll/angular";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  ExtraOptions,
  PreloadAllModules,
  Router,
  RouterModule,
} from "@angular/router";
import { CadModule } from "@cad";
import { CadConfigModule } from "@cad/services/config";
import { CadMockApiModule } from "@cad/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import {
  DisplayDensityToken,
  DisplayDensity,
} from "@infragistics/igniteui-angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AuthModule } from "app/core/auth/auth.module";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { DatePipe } from "@angular/common";

import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);

if (environment.environmentName === "STAGING") {
  Sentry.init({
    dsn: "https://71e2ee34175c433ba97783ef6a7ca0b2@report.hie.moh.gov.my/4",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["*"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.5,
  });
}

if (environment.environmentName === "QA") {
  Sentry.init({
    dsn: "https://6fea91083ce64b0fabd5cfa1e6e70cb4@report.hie.moh.gov.my/5",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["*"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.5,
  });
}

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Cad, CadConfig & CadMockAPI
    CadModule,
    CadConfigModule.forRoot(appConfig),
    CadMockApiModule.forRoot(mockApiServices),

    FormsModule,
    MbscModule,

    // Core module of your application
    CoreModule,
    //test h
    // Layout module of your application
    LayoutModule,
    AuthModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    DatePipe,
    {
      provide: DisplayDensityToken,
      useValue: { displayDensity: DisplayDensity.cosy },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
