<!-- Loading bar -->
<cad-loading-bar></cad-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <cad-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container cadVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="w-20" src="assets/images/logo/jata.png" alt="Logo image" />
      </div>
    </ng-container>
  </cad-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <img class="dark:hidden w-20" src="assets/images/logo/jata.png" />
          <img class="hidden dark:flex w-20" src="assets/images/logo/jata.png" />
        </div>
        <img class="flex lg:hidden w-8" src="assets/images/logo/jata.png" />
      </div>
      <!-- Horizontal navigation -->
      <cad-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation.horizontal">
      </cad-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <div class="flex flex-row items-center flex-auto gap-x-2">
        <button (click)="goToHelpdesk()" class="btn btn-primary btn-sm" type="button">
          Helpdesk
          <!-- <igx-icon family="material">question-mark-circle</igx-icon> -->
        </button>
        <button (click)="goToFaq()" class="btn btn-primary btn-sm" type="button">
          FAQ
          <!-- <igx-icon family="material">book-open</igx-icon> -->
        </button>
      </div>
      <div>
        <div *ngIf="preAvailable == true">
          <!-- <button
            (click)="goToPrerequisites()"
            class="btn btn-primary btn-sm"
            type="button"
          >
            <igx-icon
              family="material"
              [style.color]="'red'"
              class="blink"
              [igxTooltipTarget]="iconTooltip"
            >
              checklist_rtl</igx-icon
            >
          </button> -->
          <button mat-raised-button [color]="'warn'" (click)="goToPrerequisites()" matTooltip="Prerequisites"
            class="blink mt-2">
            <span class="text-sm">VC APPOINTMENT</span>
          </button>
          <div class="iconTooltip" #iconTooltip="tooltip" igxTooltip>
            <div class="iconTooltipContent">
              <div>
                <div>Prerequisites</div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div></div>
      <div>
        <div *ngIf="vcAvailable">
          <button (click)="goToVc()" class="btn btn-primary btn-sm" type="button">
            <igx-icon family="material" class="blink" [style.color]="'green'" [igxTooltipTarget]="iconTooltip">
              video_call</igx-icon>
          </button>
          <div class="iconTooltip" #iconTooltip="tooltip" igxTooltip>
            <div class="iconTooltipContent">
              <div>
                <div>You have VC on going</div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="!vcAvailable">
          <igx-icon family="material" [igxTooltipTarget]="iconTooltip">
            video_call</igx-icon>
          <div class="iconTooltip" #iconTooltip="tooltip" igxTooltip>
            <div class="iconTooltipContent">
              <div>
                <div>No VC at the moment</div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <languages></languages>
      <cad-fullscreen class="hidden md:block"></cad-fullscreen>
      <!-- <search [appearance]="'bar'"></search> -->
      <!--
            <shortcuts></shortcuts>
            -->
      <!--
            <messages></messages> -->

      <!--
            <notifications></notifications>
        -->
      <notification></notification>
      <user></user>
      <span class="font-medium">{{ username }}</span>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <banner></banner>
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center w-full h-10 sm:h-10 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary pr-10" *ngIf="!isScreenSmall">RekodSaya Portal &copy; {{ currentYear
      }}</span>
    <div class="rounded px-4" style="background-color: #8338ec; color: white">
      Session Timeout in {{ min }} Min
      {{ sec }}
      Sec
    </div>
  </div>
</div>