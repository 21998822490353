import { NgModule, APP_INITIALIZER } from '@angular/core';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { AuthService } from 'app/core/auth/auth.service';
import { initializer } from './keycloak-initializer';

@NgModule({
    declarations: [],
    imports: [KeycloakAngularModule],
    providers: [
      {
          provide: APP_INITIALIZER,
          useFactory: initializer,
          multi: true,
          deps: [KeycloakService]
      },
      AuthGuard,
      AuthService
    ]
  })
  export class AuthModule { }
