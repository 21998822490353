import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UserRequestService {
  constructor(
    private _httpClient: HttpClient,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _appConfigService: AppConfigService
  ) {}

  getPatient(): Observable<fhir.r4.Patient> {
    let patientId: string = this._appConfigService.getPatientResourceId();
    return this._httpClient.get<fhir.r4.Patient>(
      this._fhirConfigService.getFhirService() + "/Patient/" + patientId
    );
  }

  updatePatient(id: string, json: any): Observable<fhir.r4.Bundle> {
    return this._httpClient.put<fhir.r4.Bundle>(
      this._fhirConfigService.getFhirService() + "/Patient/" + id,
      json
    );
  }
}
