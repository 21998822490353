import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/appconfig.service';
import { FhirConfigService } from 'app/fhirconfig.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { take } from 'rxjs';

@Injectable()
export class AuthService {

  constructor(
    private _httpClient: HttpClient,
    private keycloakService: KeycloakService,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService
  ) { }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (e) {
      console.error("Exception", e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(path): void {
    this.keycloakService.logout(window.location.origin + path);
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public clearToken(): void {
    return this.keycloakService.clearToken();
  }

  public accessToken(): string {
    return this.keycloakService.getKeycloakInstance().token;
  }

  public refreshToken(): string {
    return this.keycloakService.getKeycloakInstance().refreshToken;
  }

  getLatestEncounter() {
    return this._httpClient.get(this._fhirConfigService.getFhirService() + "/Encounter?subject:mdm=Patient/" + this._appConfigService.getPatientResourceId() + "&_sort=-date&_count=1").pipe(
      take(1)
    );
  }

}